import { Component, Input } from '@angular/core';
import { ClusivityListSelectOption } from './clusivity-list-select-option.model';
import { ClusivityListItemsComponent } from '../clusivity-list-items.component';

@Component({
  selector: 'app-clusivity-list-select-options',
  templateUrl: './clusivity-list-select-options.component.html',
  styleUrls: ['./clusivity-list-select-options.component.less'],
  providers: [{
    provide: ClusivityListItemsComponent,
    useExisting: ClusivityListSelectOptionsComponent
  }]
})
export class ClusivityListSelectOptionsComponent<TItem> extends ClusivityListItemsComponent<TItem> {
  @Input({ required: true }) options: ClusivityListSelectOption<TItem>[];

  @Input() selectElementId?: string;
  @Input() selectListPlaceholderText?: string;
  @Input() maxSelectPreviewCount?: number;
  @Input() allowClear = false;
}
