import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { EMPTY, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { FILE_TYPE_TRANSLATE, SIZE_LIMIT_MB } from '../../_constants/file-upload';
import { catchError, map, takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { Common } from '../../_common/common.helper';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { UploadFileType } from '../../_models/models';


interface ShowList {
  showPreviewIcon?: boolean;
  showRemoveIcon?: boolean;
  showDownloadIcon?: boolean;
}

export interface ValidationResult {
  isValid: boolean;
  errorMessage?: string;
}
export type ValidationFunc = (file: NzUploadFile) => Observable<ValidationResult> | ValidationResult;

export enum ListType {
  TEXT = 'text',
  PICTURE = 'picture',
  PICTURE_CARD = 'picture-card'
}

export enum FileStatus {
  DONE = 'done',
  UPLOADING = 'uploading',
  REMOVED = 'removed',
  SUCCESS = 'success',
  ERROR = 'error',
}

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadFileComponent implements OnDestroy {

  @Input() uploadClass = '';

  @Input() type?: string = null;
  @Input() multipleFiles = false;
  @Input() showUploadList = true;
  @Input() numberOfFiles = 0;
  @Input() action?: string = null;
  @Input() listType?: ListType = ListType.TEXT;
  @Input() permittedFileTypes: UploadFileType[] = [];
  @Input() fileList: NzUploadFile[] = [];
  @Input() tooltip?: string = null;
  @Input() disabledUploadButton = false;
  @Input() sizeLimit: number = SIZE_LIMIT_MB;


  @Input() validationFuncs: ValidationFunc[];
  @Input() uploadingFunc: (file: NzUploadFile) => void = null;

  @Output() preDialogActions: EventEmitter<void> = new EventEmitter<void>();
  @Output() fileChange: EventEmitter<{file: NzUploadFile}> = new EventEmitter<{file: NzUploadFile}>();
  @Output() fileRemove: EventEmitter<NzUploadFile> = new EventEmitter<NzUploadFile>();

  private unsubscribe$ = new Subject<void>();

  showList: ShowList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    showDownloadIcon: false
  };

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  constructor(
    private nzNotificationService: NzNotificationService,
    private common: Common,
    private httpClient: HttpClient,
  ) { }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): Observable<boolean> => {

    if (!file) {
      return of(false);
    }

    if (!this.permittedFileTypes.includes(file.type as UploadFileType)) {
      const fileTypeText = this.getFileTypeText();
      this.nzNotificationService.error('Invalid file type', `Acceptable file types: ${fileTypeText}`);
      return of(false);
    }

    if (file.size > this.common.convertMegabytesToBytes(this.sizeLimit)) {
      this.nzNotificationService.error('Error', `File size exceeds ${this.sizeLimit} MB`);
      return of(false);
    }

    return this.handleValidationFuncs(file);
  };

  customRequest = (item: NzUploadXHRArgs): Subscription => this.isLocalProcess() ? this.handleLocalProcess(item.file) : this.handleRemoteProcess(item);

  onChange(event: { file: NzUploadFile }): void {

    const { file } = event;
    const currentStatusIsUploading = file.status === FileStatus.UPLOADING;
    if (currentStatusIsUploading && this.isLocalProcess()) {
      this.uploadingFunc?.(file);
      return;
    }

    this.fileList = [ file ];
    (file.status === FileStatus.REMOVED) ? this.onRemove(file) : this.fileChange.emit({file});
  }

  onRemove(file: NzUploadFile): void {
    this.fileRemove.emit(file);
  }

  preDialogActionsTrigger(): void {
    if (this.preDialogActions.observers.length > 0) {
      this.preDialogActions.emit();
    }
  }

  private handleValidationFuncs(file: NzUploadFile): Observable<boolean> {

    if (_.isEmpty(this.validationFuncs)) {
      return of(true);
    }

    const validationObservables = this.validationFuncs.map(fun => {
      const result = fun(file);
      return result instanceof Observable ? result : of(result);
    });

    return forkJoin(validationObservables).pipe(
      map(this.mapValidationResults),
      takeUntil(this.unsubscribe$),
      catchError(error => {
        this.nzNotificationService.error('Upload Error', error.message);
        return of(false);
      })
    );
  }

  private mapValidationResults(results: ValidationResult[]): boolean {
    for (const res of results) {
      if (!res.isValid) {
        throw new Error(res.errorMessage);
      }
    }
    return true;
  }

  private handleLocalProcess(file: NzUploadFile): Subscription {
    file.status = FileStatus.DONE;
    this.onChange({file});
    return EMPTY.subscribe();
  }

  private handleRemoteProcess(item: NzUploadXHRArgs): Subscription {
    const formData = new FormData();
    formData.append(item.name || 'file', item.file as any);

    const req = new HttpRequest('POST', item.action!, formData, {
      headers: new HttpHeaders(item.headers),
      withCredentials: item.withCredentials,
      reportProgress: true
    });

    return this.httpClient.request(req).subscribe({
      next: (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total) {
            const percent = Math.round((event.loaded / event.total) * 100);
            item.onProgress!({ percent }, item.file);
          }
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file, event);
        }
      },
      error: (err) => {
        item.onError!(err, item.file);
      }
    });
  }

  private isLocalProcess(): boolean {
    return _.isEmpty(this.action);
  }

  private getFileTypeText(): string {
    return [... new Set(Object.values(this.permittedFileTypes).map(type => FILE_TYPE_TRANSLATE[type]))].join(', ');
  }

}
