import { ClusivityType } from './models';

export type ClusivityList<TItem> = {
  clusivity: ClusivityType | null;
  items: TItem[];
};

export function createEmptyClusivityList<TItem>(): ClusivityList<TItem> {
  return { clusivity: null, items: [] };
}

