<div class="display-flex justify-content-space-between align-items-center gap-1">

    <app-upload-file
      [uploadClass]="'logo-uploader'"
      [sizeLimit]="sizeLimit"
      [listType]="listType"
      [permittedFileTypes]="logoFileTypes"
      [tooltip]="imgSource ? uploadFileInstruction : ''"
      [showUploadList]="false"
      [multipleFiles]="false"
      [uploadingFunc]="uploadingFunc.bind(this)"
      (fileChange)="handleChange($event)"
      (fileRemove)="removeImg"
      >
      <ng-container *ngIf="!imgSource">
        <div class="display-flex align-items-center gap-1">
          <span class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></span>
          <div class="ant-upload-text">
            <div>
              <p class="small-font semibold-font">{{uploadText}}</p>
              <p class="smaller-font margin-top-1">{{uploadFileInstruction}}</p>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="imgSource" class="img-wrapper">
        <div class="img">
          <img [src]="imgSource" alt="uploaded logo"/>
        </div>
        <div class="text">
          <p>{{uploadText}}</p>
        </div>
      </div>
    </app-upload-file>

    <button *ngIf="imgSource && !hideRemoveBtn" nz-popconfirm [nzPopconfirmTitle]="deleteImgPopupConfirm"
            (nzOnConfirm)="removeImg()" nz-button nzType="default" nzShape="circle">
      <span nz-icon nzType="delete" nzTheme="outline"></span>
    </button>
  </div>
  
  <ng-template #deleteImgPopupConfirm>
    <fa-icon icon="exclamation-circle"
             class="font-icon-color-warning-mid-dark margin-right-small"></fa-icon>
    Are you sure you want to delete this logo?
  </ng-template>
  