import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ClipboardService } from 'ngx-clipboard';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { GeoTargetingService } from './_services/geo-targeting.service';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import {
  Country,
  ExchangeDealGeoTargeting,
  GeoTargetingFilterList,
  JapiQuery,
  Metro,
  ResponseFromServer,
  State
} from '../../_models/models';
import { isEmpty } from 'lodash';
import { SharedStoreService } from '../../_services/shared-store.service';

@Component({
  selector: 'app-geo-targeting',
  templateUrl: './geo-targeting.component.html',
  styleUrls: ['./geo-targeting.component.less']
})
export class GeoTargetingComponent implements OnInit {
  @Input() currentGeoTargeting: ExchangeDealGeoTargeting;
  @Output() updatedGeoTargetingEvent = new EventEmitter<UntypedFormGroup>();
  @Input() isEditMode = false;

  form: UntypedFormGroup;
  countries: Country[] = [];
  regions: State[] = [];
  metros: Metro[] = [];

  selectIcons: { [p: string]: boolean } = {};

  readonly EMPTY_STRING = '';
  readonly INCLUDE = 'include';

  geoCountryRadioValue = this.EMPTY_STRING;
  geoRegionRadioValue = this.EMPTY_STRING;
  geoMetroRadioValue = this.EMPTY_STRING;
  geoZipRadioValue = this.EMPTY_STRING;
  isSingleCountrySelected = false;
  isCopied_zipCodes = false;

  zipCodeList: Array<{ zipCode: string }> = [];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private clipboardService: ClipboardService,
    private notification: NzNotificationService,
    private geoTargetingService: GeoTargetingService,
    private sharedStoreService: SharedStoreService,
  ) { }

  ngOnInit() {
    this.initForm();

    this.sharedStoreService.getSharedAsset('countries')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((countries) => {
        this.countries = countries.data;
        this.patchFormValues();
      });
    this.setCurrentGeoFilters();
    this.subscribeFormChanges();
  }

  subscribeFormChanges(): void {
    this.form.controls.filterCountryList.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((selectedCountries: GeoTargetingFilterList[]) => {
        this.resetFilterListsValue();

        if (selectedCountries?.length === 1) {
          this.handleOneSelectedCountry(selectedCountries[0].queryProp);
        } else {
          if (selectedCountries?.length > 1) {
            this.handleMultipleCountriesSelected();
          }
          if (selectedCountries?.length === 0) {
            this.handleNoCountriesSelected();
          }
        }
        this.updateFilterRadioButtonValue('filterCountry', 'filterCountryList', this.geoCountryRadioValue);
      });

    this.form.controls.filterRegionList.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$))
      .subscribe((selectedRegions: string[]) => {
        this.geoRegionRadioValue = this.setFilterRadioButtons(selectedRegions, this.geoRegionRadioValue, 'filterRegionList');
        if (selectedRegions?.length === 0) {
          this.geoRegionRadioValue = this.EMPTY_STRING;
        }
        this.updateFilterRadioButtonValue('filterRegion', 'filterRegionList', this.geoRegionRadioValue);
      });

    this.form.controls.filterDmaList.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
    )
      .subscribe((selectedMetros: string[]) => {
        this.geoMetroRadioValue = this.setFilterRadioButtons(selectedMetros, this.geoMetroRadioValue, 'filterDmaList');
        this.updateFilterRadioButtonValue('filterDma', 'filterDmaList', this.geoMetroRadioValue);
      });

    this.form.controls.filterZipCodeList.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    )
      .subscribe((selectedZipCodes: string[]) => {
        this.geoZipRadioValue = this.setFilterRadioButtons(selectedZipCodes, this.geoZipRadioValue, 'filterZipCodeList');
        this.updateFilterRadioButtonValue('filterZipCode', 'filterZipCodeList', this.geoZipRadioValue);
      });

    this.form.controls.filterCountry.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.updatedGeoTargetingEvent.emit(this.form);
      });

    this.form.controls.filterRegion.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.updatedGeoTargetingEvent.emit(this.form);
      });

    this.form.controls.filterDma.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.updatedGeoTargetingEvent.emit(this.form);
      });

    this.form.controls.filterZipCode.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.updatedGeoTargetingEvent.emit(this.form);
      });
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      filterCountryList: null,
      filterRegionList: null,
      filterDmaList: null,
      filterZipCodeList: [null, [this.forbiddenCharValidator]],
      filterCountry: this.EMPTY_STRING,
      filterRegion: null,
      filterDma: null,
      filterZipCode: null,
    });

    this.disableFilterLists();
  }

  isRadioDisabled(type: string): boolean {
    return null == this.form.controls[type].value || this.form.controls[type].value.length === 0;
  }

  onChangeSelectIconState(isOpen: boolean, id: string): void {
    this.selectIcons[id] = isOpen;
  }

  compareByName = (o1: GeoTargetingFilterList, o2: GeoTargetingFilterList) => o1 && o2 ?
    o1.nameProp.toLowerCase() === o2.nameProp.toLowerCase()
    : o1 === o2;

  compareByApiProp = (o1: GeoTargetingFilterList, o2: GeoTargetingFilterList) => o1 && o2 ?
    o1.apiProp === o2.apiProp
    : o1 === o2;

  addToZipCodes(event): void {
    if (event) {
      this.zipCodeList = [...event];
    }
  }

  public clipboardCopy(text: string, inlineNotification = false, isCopiedVarName = null): void {
    this.clipboardService.copy(text);
    if (inlineNotification) {
      this[isCopiedVarName] = true;
      setTimeout(() => {
        this[isCopiedVarName] = false;
      }, 1500);
    } else {
      this.notification.success('Success', 'Copied to clipboard.');
    }
  }

  setCurrentGeoFilters(): void {
    this.geoCountryRadioValue = this.currentGeoTargeting?.filterCountry || this.EMPTY_STRING;
    this.geoRegionRadioValue = this.currentGeoTargeting?.filterRegion || this.EMPTY_STRING;
    this.geoMetroRadioValue = this.currentGeoTargeting?.filterDma || this.EMPTY_STRING;
    this.geoZipRadioValue = this.currentGeoTargeting?.filterZipCode || this.EMPTY_STRING;
  }

  setFilterRadioButtons(selectedList: string [], currentRadioVal: string | null, geoFilterListName: string): string {
    if (currentRadioVal === this.EMPTY_STRING) {
      return this.setRadioButtonState(selectedList, currentRadioVal);
    }
    if (!this.isEditMode && isEmpty(selectedList)) {
      return this.EMPTY_STRING;
    }
    if (this.isEditMode && isEmpty(this.form.get(geoFilterListName).value) && isEmpty(selectedList)) {
      return this.EMPTY_STRING;
    }
    return currentRadioVal;
  }

  setRadioButtonState(selections: string[], radioButtonState: string): string {
    if (!selections || selections.length === 0) {
      return this.EMPTY_STRING;
    }

    if (radioButtonState === this.EMPTY_STRING) {
      return this.INCLUDE;
    }
  }

  getRegionsAndMetros(selectedCountry): Observable<[ResponseFromServer<State[]>, ResponseFromServer<Metro[]>]> {
    const query = {
      filter: {
        filters: [{
          fieldName: 'country', operation: 'IN', value: selectedCountry
        }]
      }
    } as JapiQuery;
    return forkJoin([this.geoTargetingService.getRegions(query), this.geoTargetingService.getMetros(query)])
      .pipe(tap(([regionsRes, metrosRes]) => {
        this.regions = regionsRes.data;
        this.metros = metrosRes.data;
      }));
  }

  getFilterRadioButtonValueForPayload(selectedList: string [], radioButtonValue: string): string {
    if (selectedList?.length && radioButtonValue) {
      return radioButtonValue;
    }
    return this.EMPTY_STRING;
  }

  convertArrayToArrayOfObjects(type: string, arr: string[]): GeoTargetingFilterList[] {
    switch (type) {
      case 'countries':
        return arr
          .map(alpha2 => this.countries.find(country => country.alpha2 === alpha2))
          .map(country => ({nameProp: country.name, apiProp: country.alpha2, queryProp: country.alpha3}));
      case 'regions':
        return arr
          .map(elem => this.regions.find(region => region.region === elem))
          .map(region => ({nameProp: region.displayRegion, apiProp: region.regionKey, queryProp: region.region}));
      case 'metros':
        return arr
          .map(metroName => this.metros.find(metro => metro.metroName === metroName))
          .map(foundMetro => ({nameProp: foundMetro.metroName, apiProp: foundMetro.metroKey, queryProp: foundMetro.metroKey}));
    }
  }

  updateRadioButtonValue(radioButtonFormControl: string, filterListFormControl: string, radioGroupModel: string) {
    const updatedRadioValue = this.getFilterRadioButtonValueForPayload(this.form.get(filterListFormControl).value, this[radioGroupModel]);
    this.form.get(radioButtonFormControl).setValue(updatedRadioValue);
    this.updatedGeoTargetingEvent.emit(this.form);
  }

  private disableFilterLists(): void {
    this.form.controls.filterRegionList.disable();
    this.form.controls.filterDmaList.disable();
    this.form.controls.filterZipCodeList.disable();
  }

  private enableFilterLists(): void {
    this.form.controls.filterRegionList.enable();
    this.form.controls.filterDmaList.enable();
    this.form.controls.filterZipCodeList.enable();
  }

  private resetFilterRadioButtons(): void {
    this.geoCountryRadioValue = this.EMPTY_STRING;
    this.geoRegionRadioValue = this.EMPTY_STRING;
    this.geoMetroRadioValue = this.EMPTY_STRING;
    this.geoZipRadioValue = this.EMPTY_STRING;
  }

  private updateFilterListsValueAndValidity(): void {
    this.form.controls.filterRegionList.updateValueAndValidity();
    this.form.controls.filterDmaList.updateValueAndValidity();
    this.form.controls.filterZipCodeList.updateValueAndValidity();
  }

  private resetFilterListsValue(): void {
    this.form.controls.filterRegionList.setValue([], {emitEvent: false});
    this.form.controls.filterDmaList.setValue([], {emitEvent: false});
    this.form.controls.filterZipCodeList.setValue([], {emitEvent: false});
  }

  private handleOneSelectedCountry(queryProp: string | number): void {
    if (this.geoCountryRadioValue === this.EMPTY_STRING) {
      this.geoCountryRadioValue = this.INCLUDE;
    }
    this.isSingleCountrySelected = true;

    this.getRegionsAndMetros(queryProp).subscribe(() => {
      this.enableFilterLists();
      this.updateFilterListsValueAndValidity();
    });
  }

  private patchFormValues() {
    let adjustedCountries;
    this.isSingleCountrySelected = this.currentGeoTargeting?.countries?.length === 1;

    if (this.currentGeoTargeting?.countries?.length > 0) {
      adjustedCountries = this.convertArrayToArrayOfObjects('countries', this.currentGeoTargeting.countries);
      this.form.get('filterCountryList').setValue(adjustedCountries, {emitEvent: false});
    }
    if (this.isSingleCountrySelected) {
      this.enableFilterLists();
      this.updateFilterListsValueAndValidity();

      this.getRegionsAndMetros(adjustedCountries[0].queryProp).subscribe(() => {
        if (this.currentGeoTargeting?.regions?.length > 0) {
          const adjustedRegions = this.convertArrayToArrayOfObjects('regions', this.currentGeoTargeting.regions);
          this.form.get('filterRegionList').setValue(adjustedRegions);

          if (this.geoCountryRadioValue === this.EMPTY_STRING) {
            this.geoCountryRadioValue = this.INCLUDE;
          }
        }

        if (this.currentGeoTargeting?.metros?.length > 0) {
          const adjustedMetros = this.convertArrayToArrayOfObjects('metros', this.currentGeoTargeting.metros);
          this.form.get('filterDmaList').setValue(adjustedMetros);
        }

        if (this.currentGeoTargeting?.zipCodes?.length > 0) {
          this.form.get('filterZipCodeList').setValue(this.currentGeoTargeting.zipCodes);
        }

        if (this.currentGeoTargeting?.filterCountry) {
          this.form.get('filterCountry').setValue(this.currentGeoTargeting.filterCountry);
        }
      });
    }
  }

  private handleMultipleCountriesSelected(): void {
    if (this.geoCountryRadioValue === this.EMPTY_STRING) {
      this.geoCountryRadioValue = this.INCLUDE;
    }
    this.isSingleCountrySelected = false;
    this.updatedGeoTargetingEvent.emit(this.form);
    this.disableFilterLists();
  }

  private handleNoCountriesSelected(): void {
    this.resetFilterRadioButtons();
    this.resetFilterListsValue();
    this.updateFilterListsValueAndValidity();
    this.updatedGeoTargetingEvent.emit(this.form);
    this.disableFilterLists();
  }

  private updateFilterRadioButtonValue(radioButtonFormControl: string, filterListFormControl: string, currentButtonValue: string) {
    const updatedRadioValue = this.getFilterRadioButtonValueForPayload(this.form.get(filterListFormControl).value, currentButtonValue);
    this.form.get(radioButtonFormControl).setValue(updatedRadioValue);
    this.updatedGeoTargetingEvent.emit(this.form);
  }

  private forbiddenCharValidator = (control: AbstractControl): { [key: string]: any } | null => {
    const values: string[] = control.value;
    if (!values || !Array.isArray(values)) {
      return null;  // If the value is not an array or undefined, consider it valid
    }
    const isStringValid = values.every((value: string) => /^[a-zA-Z0-9- ]*$/.test(value));
    return !isStringValid ? { specialCharsErrorMsg: 'Zip code cannot contain special characters' } : null;
  };

}
