import { UploadFileType } from "../_models/models";

export const SIZE_LIMIT_MB = 8;

export const FILE_TYPE_TRANSLATE: Record<UploadFileType, string> = {
  [UploadFileType.APPLICATION_PDF]: 'PDF',
  [UploadFileType.TEXT_PLAIN]: 'TXT',
  [UploadFileType.APPLICATION_VND_MS_EXCEL]: 'Excel',
  [UploadFileType.TEXT_X_CSV]: 'CSV',
  [UploadFileType.APPLICATION_CSV]: 'CSV',
  [UploadFileType.APPLICATION_X_CSV]: 'CSV',
  [UploadFileType.TEXT_CSV]: 'CSV',
  [UploadFileType.TEXT_COMMA_SEPARATED_VALUES]: 'CSV',
  [UploadFileType.TEXT_X_COMMA_SEPARATED_VALUES]: 'CSV',
  [UploadFileType.TEXT_TAB_SEPARATED_VALUES]: 'CSV',
  [UploadFileType.IMAGE_PNG]: 'PNG',
  [UploadFileType.IMAGE_JPEG]: 'JPEG',
  [UploadFileType.IMAGE_JPG]: 'JPG',
};

export const CSV_FILES = [
  UploadFileType.APPLICATION_CSV,
  UploadFileType.APPLICATION_X_CSV,
  UploadFileType.TEXT_CSV,
  UploadFileType.TEXT_COMMA_SEPARATED_VALUES,
  UploadFileType.TEXT_X_COMMA_SEPARATED_VALUES,
  UploadFileType.TEXT_TAB_SEPARATED_VALUES,
]


