import { AbstractControl } from "@angular/forms";
import { Observable, Subject } from "rxjs";

// Replace this implementation with 'events' observable of AbstractControl
// after upgrade to Angular v18.
export function extractOnTouchedStream(control: AbstractControl): Observable<void> {
    const prevMarkAsTouched = control.markAsTouched.bind(control);
    const onTouchedStream = new Subject<void>();

    function nextMarkAsTouched(...args: any[]) {
        prevMarkAsTouched(...args);
        onTouchedStream.next();
    }

    control.markAsTouched = nextMarkAsTouched;
    return onTouchedStream;
}