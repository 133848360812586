import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { ListType } from '../upload-file/upload-file.component';
import { UploadFileType } from '../../_models/models';

@Component({
  selector: 'app-upload-logo',
  templateUrl: './upload-logo.component.html',
  styleUrls: ['./upload-logo.component.less']
})
export class UploadLogoComponent {
  
  imgSource?: string;
  sizeLimit: number = 2;
  listType: ListType = ListType.PICTURE_CARD;
  logoFileTypes: UploadFileType[] = [
    UploadFileType.IMAGE_JPG,
    UploadFileType.IMAGE_PNG,
  ];

  @Input() uploadText = 'Upload';
  @Input() hideRemoveBtn = false;
  @Input() uploadFileInstruction = `Accepts PNG and JPG files, max ${this.sizeLimit}MB`;
  @Input() set updateUrlImg(value: string) {
    if (!this.imgSource && value && value.startsWith('http')) {
      this.imgSource = value;
    }
  }
  @Output() fileUpload: EventEmitter<NzUploadXHRArgs> = new EventEmitter<NzUploadXHRArgs>();

  
  loading = false;

  handleChange(info: { file?: NzUploadFile }): void {
    this.fileUpload.emit({file: info.file, postFile: info.file })
    this.loading = false;
  }

  uploadingFunc(file: NzUploadFile): void {
    this.loading = true;
    this.getBase64(file.originFileObj, (img: string) => {
      this.loading = false;
      this.imgSource = img;
    });
  }

  removeImg(): void {
    this.imgSource = null;
    this.fileUpload.emit(null);
  }

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result.toString()));
    reader.readAsDataURL(img);
  }
}
