import { Injectable } from '@angular/core';
import { Timezone } from '../_models/models';
import * as _ from 'lodash';
import dayjs from 'dayjs';
import { Observable, Observer, throwError } from 'rxjs';
import * as Papa from 'papaparse';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable()
export class Common {
  constructor(private readonly nzNotificationService: NzNotificationService) {
  }

  public match(str, rule): boolean {
    return new RegExp('^' + rule.split('*').join('.*') + '$').test(str);
  }

  public convertToETTimezone(date: any = null): string {
    if (date) {
      return new Date(date).toLocaleString('en-US', {timeZone: 'America/New_York'});
    } else {
      return new Date().toLocaleString('en-US', {timeZone: 'America/New_York'});
    }
  }

  public getGmtOffset(date: string): string {
    // works only for dates in this format: 2021-03-17T04:52:40.000-0400
    const offset = +date.substr(date.length - 5, 3);
    const str = `GMT${Math.sign(offset) >= 0 ? '+' : ''}${offset}`;
    return str;
  }

  public convertTimestampToEstDateTime(timestamp: number, isMidnight = false, isBeforeMidnight = false): string {
    if (!timestamp) {
      return '';
    }
    const resDate = dayjs(new Date(timestamp).toLocaleString('en-US', {timeZone: 'America/New_York'})).toDate();
    if (isMidnight) {
      resDate.setHours(0);
      resDate.setMinutes(0);
      resDate.setSeconds(0);
      resDate.setMilliseconds(0);
    }
    return dayjs(resDate, 'MM/DD/YYYY, h:mm:ss A').subtract(isBeforeMidnight ? 1 : 0, 'minute')
      .format('MM/DD/YYYY h:mm A');
  }

  public convertTimestampToEstDateString(timestamp: string): string {
    return dayjs.tz(dayjs(timestamp), 'America/New_York').format('MM/DD/YYYY HH:mm:ss');
  }

  public timezoneToDropdownLabel(tz: Timezone): string {
    return `(GMT${Math.sign(tz.gmtOffset) >= 0 ? '+' : '-'}${Math.abs(tz.gmtOffset).toString().padStart(2, '0')}:00) ${tz.abbreviation}`;
  }

  public downloadFile(data: any, file: string): void {
    const blob = new Blob([data], {type: 'octet/stream'});
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = file;
    anchor.href = url;
    anchor.click();
  }

  public downloadFileFromUrl(url: string, name: string): void {
    const anchor = document.createElement('a');
    anchor.download = name; // doesn't work - uses the file name from the url
    anchor.href = url;
    anchor.click();
  }

  public toQueryParamsString(params: { [key: string]: any }): string {
    let string = '?';
    Object.keys(params).forEach((key, idx) => {
      string += `${key}=${params[key]}`;
      if (idx !== Object.keys(params).length - 1) {
        string += '&';
      }
    });
    return string;
  }

  public toQueryParamsObject(locationSearch: string): { [key: string]: any } {
    if (locationSearch) {
      const search = locationSearch.substring(1);
      return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
    }
    return null;
  }

  public flattenKeys(obj, path = []) {
    if (!_.isObject(obj)) {
      return {[path.join('.')]: obj};
    }
    return _.reduce(obj, (cum, next, key) => _.merge(cum, this.flattenKeys(next, [...path, key])), {});
  }

  public generatePassword(config: {
    lowercase?: boolean,
    uppercase?: boolean,
    numbers?: boolean,
    symbols?: boolean,
    length?: number
  } = {}) {
    const DEFAULTS = {
      lowercase: true,
      uppercase: true,
      numbers: true,
      symbols: true,
      length: 12
    };
    config = _.assign(DEFAULTS, config);
    const chars = {
      lowercase: 'abcdefghijklmnopqrstuvwxyz',
      uppercase: 'ABCDEFGHIJKLMNOPWRSTUVWXYZ',
      numbers: '0123456789',
      symbols: '!()*-.',
    };
    // Create array of optional chars
    const dictionary = [].concat(
      config.lowercase ? chars.lowercase.split('') : [],
      config.uppercase ? chars.uppercase.split('') : [],
      config.numbers ? chars.numbers.split('') : [],
      config.symbols ? chars.symbols.split('') : []
    );

    let newPassword = '';
    newPassword += config.lowercase ? chars.lowercase[Math.floor(Math.random() * chars.lowercase.length)] : '';
    newPassword += config.uppercase ? chars.uppercase[Math.floor(Math.random() * chars.uppercase.length)] : '';
    newPassword += config.numbers ? chars.numbers[Math.floor(Math.random() * chars.numbers.length)] : '';
    newPassword += config.symbols ? chars.symbols[Math.floor(Math.random() * chars.symbols.length)] : '';
    const remainingLength = config.length - newPassword.length;

    // Generate random password from array
    for (let i = 0; i < config.length - remainingLength; i++) {
      newPassword += dictionary[Math.floor(Math.random() * dictionary.length)];
    }

    return newPassword;
  }

  public mapToTitleValueKeyOptions(list: any[], titleKey: string, valueKey: string, keyKey: string): Array<{
    title: string,
    value: any,
    key: any
  }> {
    return list.map(obj => Object.assign({
      title: obj[titleKey],
      value: obj[valueKey],
      key: obj[keyKey],
    }));
  }

  /**
   * Parses a CSV file and returns the data as an Observable.
   *
   * @param file The CSV file to be parsed.
   * @param header Set to true if the first row of the CSV contains headers.
   * @param dynamicTyping Automatically convert numeric values if true.
   * @param delimitersToIgnore Ignored delimiters (default delimiters are: comma, tab, pipe, semicolon, record separator, unit separator).
   * @returns An Observable that emits the parsed CSV data or an error if parsing fails.
   */
  public parseCSV<T>(file: File, header = true, dynamicTyping = true, delimitersToIgnore: string[] = []): Observable<T[]> {
    const delimitersToGuess = [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP].filter((d) => !delimitersToIgnore.includes(d));
    try {
      return new Observable((observer: Observer<T[]>) => {
        Papa.parse(file, {
          complete: (result) => {
            observer.next(result.data);
            observer.complete();
          },
          header,
          dynamicTyping,
          delimitersToGuess,
          error: (error) => {
            observer.error(error);
          },
        });
      });
    } catch (e) {
      return throwError(e);
    }
  }

  public convertMegabytesToBytes(mb: number): number {
    return mb * 1024 * 1024;
  }

}
