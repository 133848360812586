import { Directive, OnDestroy, Provider } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { isEqual } from 'lodash';
import { asyncScheduler, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, observeOn } from 'rxjs/operators';
import { extractOnTouchedStream } from 'src/app/shared/_common/touched-stream.helper';

@Directive()
export abstract class FormPartComponent<TModel, TFormPart extends AbstractControl>
implements ControlValueAccessor, Validator, OnDestroy {
  protected readonly subscriptions = new Subscription();
  private _onTouchedStream: Observable<void>;

  constructor(private readonly changesStreamDebounceTimeInMs: number = 20) { }

  private get onTouchedStream(): Observable<void> {
    if (!this._onTouchedStream) {
      this._onTouchedStream = extractOnTouchedStream(this.formPart);
    }
    return this._onTouchedStream;
  }

  protected abstract get formPart(): TFormPart;

  protected static createDirectiveProviders(componentClass: object): Provider[] {
    return [
      { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: componentClass },
      { provide: NG_VALIDATORS, multi: true, useExisting: componentClass }
    ];
  }

  writeValue(newValue: TModel): void {
    this.formPart.patchValue(newValue);
  }

  registerOnChange(onChangeCallback: (newValue: TModel) => void): void {
    const changesStream = this.createChangesStream(this.changesStreamDebounceTimeInMs);
    const subscription = changesStream.subscribe(newValue => onChangeCallback(newValue));
    this.subscriptions.add(subscription);
  }

  registerOnTouched(onTouchedCallback: () => void): void {
    const subscription = this.onTouchedStream.subscribe(() => onTouchedCallback());
    this.subscriptions.add(subscription);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formPart.disable();
    } else {
      this.formPart.enable();
    }
  }

  validate(): ValidationErrors {
    if (this.formPart.valid) return null;
    return this.getFormPartValidationErrors();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected createChangesStream(changesStreamDebounceTimeInMs: number = 0): Observable<TModel> {
    let changesStream = this.formPart.valueChanges;

    if (changesStreamDebounceTimeInMs > 0) {
      changesStream = changesStream.pipe(debounceTime(changesStreamDebounceTimeInMs, asyncScheduler));
    } else {
      changesStream = changesStream.pipe(observeOn(asyncScheduler));
    }

    changesStream = changesStream.pipe(distinctUntilChanged<TModel>((first, second) => isEqual(first, second)));
    return changesStream;
  }

  protected abstract getFormPartValidationErrors(): ValidationErrors;
}
