<form nz-form nzLayout="vertical" [formGroup]="form">
  <nz-row nzGutter="32">
    <nz-col nzLg="8" nzMd="12" nzSm="24">
      <nz-form-item>
        <div nz-row nzJustify="space-between" class="align-items-baseline">
          <nz-form-label class="margin-top-small"
                          nz-tooltip="Region, Metro and Zip codes can be selected only if single country selected">
            Country
          </nz-form-label>
          <nz-radio-group [(ngModel)]="geoCountryRadioValue"
                          (ngModelChange)="updateRadioButtonValue('filterCountry', 'filterCountryList',  'geoCountryRadioValue')"
                          [nzDisabled]="isRadioDisabled('filterCountryList')"
                          [ngModelOptions]="{standalone: true}"
                          id="geo-radio-country-blacklist-whitelist">
            <label nz-radio nzValue="include">Include</label>
            <label nz-radio nzValue="exclude">Exclude</label>
          </nz-radio-group>
        </div>
        <nz-form-control>
          <nz-select formControlName="filterCountryList"
                      id="package_countries"
                      nzShowSearch
                      nzAllowClear
                      nzMode="multiple"
                      [compareWith]="compareByName"
                      [nzMaxTagCount]="2"
                      [nzPlaceHolder]="'Select countries'"
                      nzShowArrow="false"
                      (nzOpenChange)="onChangeSelectIconState($event, 'package_countries')">
            <nz-option *ngFor="let option of countries"
                        [nzLabel]="option.name"
                        [nzValue]="{nameProp: option.name, apiProp: option.alpha2, queryProp: option.alpha3}">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzLg="8" nzMd="12" nzSm="24">
      <nz-form-item>
        <div nz-row nzJustify="space-between" class="align-items-baseline">
          <nz-form-label class="margin-top-small">Region/State</nz-form-label>
          <nz-radio-group [(ngModel)]="geoRegionRadioValue"
                          (ngModelChange)="updateRadioButtonValue('filterRegion', 'filterRegionList',  'geoRegionRadioValue')"
                          [nzDisabled]="isRadioDisabled('filterRegionList')"
                          [ngModelOptions]="{standalone: true}"
                          id="geo-radio-region-blacklist-whitelist">
            <label nz-radio nzValue="include">Include</label>
            <label nz-radio nzValue="exclude">Exclude</label>
          </nz-radio-group>
        </div>
        <nz-form-control
          nz-tooltip
          [nzTooltipMouseEnterDelay]="0.5"
          [nzTooltipTrigger]="isSingleCountrySelected ? null : 'hover'"
          [nzTooltipTitle]="isSingleCountrySelected ? '' : 'Regions can be added only if single country selected'">
          <nz-select formControlName="filterRegionList"
                      id="package_regions"
                      nzShowSearch
                      nzMode="multiple"
                      nzAllowClear
                      [compareWith]="compareByName"
                      [nzMaxTagCount]="2"
                      [nzPlaceHolder]="'Select regions/states'"
                      nzShowArrow="false"
                      (nzOpenChange)="onChangeSelectIconState($event, 'package_regions')">
            <nz-option *ngFor="let option of regions"
                        [nzLabel]="option.displayRegion | titlecase"
                        [nzValue]="{nameProp: option.displayRegion, apiProp: option.regionKey, queryProp: option.region}">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzLg="8" nzMd="12" nzSm="24">
      <nz-form-item>
        <div nz-row nzJustify="space-between" class="align-items-baseline">
          <nz-form-label class="margin-top-small">Metro</nz-form-label>
          <nz-radio-group [(ngModel)]="geoMetroRadioValue"
                          (ngModelChange)="updateRadioButtonValue('filterDma', 'filterDmaList',  'geoMetroRadioValue')"
                          [nzDisabled]="isRadioDisabled('filterDmaList')"
                          [ngModelOptions]="{standalone: true}"
                          id="geo-radio-metro-blacklist-whitelist">
            <label nz-radio nzValue="include">Include</label>
            <label nz-radio nzValue="exclude">Exclude</label>
          </nz-radio-group>
        </div>
        <nz-form-control
          nz-tooltip
          [nzTooltipMouseEnterDelay]="0.5"
          [nzTooltipTrigger]="isSingleCountrySelected ? null : 'hover'"
          [nzTooltipTitle]="isSingleCountrySelected ? '' : 'Metros can be added only if single country selected'">
          <nz-select formControlName="filterDmaList"
                      id="package_metro"
                      nzShowSearch
                      nzMode="multiple"
                      nzAllowClear
                      [compareWith]="compareByApiProp"
                      [nzMaxTagCount]="2"
                      [nzPlaceHolder]="'Select metros'"
                      nzShowArrow="false"
                      (nzOpenChange)="onChangeSelectIconState($event, 'package_metro')">
            <nz-option *ngFor="let option of metros"
                        [nzLabel]="option.displayMetroName"
                        [nzValue]="{nameProp: option.metroName, apiProp: option.metroKey, queryProp: option.metroKey}">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzLg="8" nzMd="12" nzSm="24">
      <nz-form-item>
        <div nz-row nzJustify="space-between" class="align-items-baseline">
          <nz-form-label class="margin-top-small">Zip Codes</nz-form-label>
          <nz-radio-group [(ngModel)]="geoZipRadioValue"
                          (ngModelChange)="updateRadioButtonValue('filterZipCode', 'filterZipCodeList',  'geoZipRadioValue')"
                          [nzDisabled]="isRadioDisabled('filterZipCodeList')"
                          [ngModelOptions]="{standalone: true}"
                          id="geo-radio-zip-blacklist-whitelist">
            <label nz-radio nzValue="include">Include</label>
            <label nz-radio nzValue="exclude">Exclude</label>
          </nz-radio-group>
        </div>
        <nz-form-control nz-tooltip
                          [nzErrorTip]="form.controls.filterZipCodeList.getError('specialCharsErrorMsg')"
                          [nzTooltipMouseEnterDelay]="0.5"
                          [nzTooltipTrigger]="isSingleCountrySelected ? null : 'hover'"
                          [nzTooltipTitle]="isSingleCountrySelected ? '' : 'Zip codes can be added only if single country selected'">
          <nz-input-group [nzSuffix]="copyZipCodesBtnTpl" class="suffix-margin-right-1">
            <nz-select formControlName="filterZipCodeList"
                        [nzTokenSeparators]="[',', ' ']"
                        class="top-control-no-border"
                        [nzSize]="'width: 100%'"
                        id="package_postal_codes"
                        nzPlaceHolder="Use a comma or an enter to separate items in list"
                        nzMode="tags"
                        nzShowArrow="false"
                        nzAllowClear
                        nzShowSearch
                        [nzMaxTagCount]="5"
                        (nzOpenChange)="onChangeSelectIconState($event, 'package_postal_codes')"
                        (ngModelChange)="addToZipCodes($event)">
            </nz-select>
          </nz-input-group>
          <ng-template #copyZipCodesBtnTpl>
            <div class="display-flex align-items-center">
                <span class="smaller-font bold-font font-icon-color-darker margin-right-small background-white"
                      *ngIf="isCopied_zipCodes" @fadeOut>Copied to clipboard</span>
              <fa-icon icon="copy"
                        *ngIf="form.get('filterZipCodeList').value && form.get('filterZipCodeList').value.length"
                        (click)="clipboardCopy(form.get('filterZipCodeList').value, true, 'isCopied_zipCodes')"
                        class="font-icon-color-darker cursor-pointer background-white margin-right-small"
                        id="copyToClipboardBtn-apReviewAppSites"></fa-icon>
            </div>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  </form>
