import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContractFile, PublisherEdit, PublisherPayment, UploadFileType } from '../../_models/models';
import { SupplyPublisherService } from '../../../features/supply/publishers/_services/publisher.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload/interface';
import { UPLOAD_FILE_URL } from '../../../features/supply/publishers/_services/publishers.config';
import { AbstractControl } from '@angular/forms';
import { FileUploadService } from '../../_services/file-upload.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { FileStatus } from '../upload-file/upload-file.component';

@Component({
  selector: 'app-upload-contract',
  templateUrl: './upload-contract.component.html',
  styleUrls: ['./upload-contract.component.less']
})
export class UploadContractComponent implements OnInit {

  contractHasBeenUploaded = false;
  contractFile: NzUploadFile[] = [];
  uploadedContractFiles: ContractFile[];
  isRequired = false;
  fileTypes: UploadFileType[] = [
    UploadFileType.APPLICATION_PDF,
  ]

  private _publisherData: PublisherEdit;
  @Input()
  set publisherData(publisherData: PublisherEdit) {
    this._publisherData = publisherData;
    this.isRequired = !(publisherData.publisherTermsOfServices?.length ?? 0) &&
      !(publisherData.payment?.publisherFinancialDocument?.filter(document => document.docType === 'PAYMENT_INFO').length ?? 0);
  }
  get publisherData() {
    return this._publisherData;
  }
  @Input() parentFormControl: { [p: string]: AbstractControl };

  @Output() contractFileChange = new EventEmitter<void>();

  constructor(
    private publisherService: SupplyPublisherService,
    private notification: NzNotificationService,
    private fileUploadService: FileUploadService,
  ) {
  }

  ngOnInit(): void {
    this.calcContractFileUrl();
  }

  startDownload(data, filename): void {
    const blob = new Blob([data], {type: 'application/pdf'});
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename.contractFileName ? filename.contractFileName : filename.taxFileName;
    // start download
    a.click();
  }

  calcContractFileUrl(): void {
    const financialDocs = this.publisherData.payment?.publisherFinancialDocument;
    this.uploadedContractFiles = [];
    if (financialDocs) {
      financialDocs.forEach((doc) => {
        if (doc?.docType === 'PAYMENT_INFO') {
          if (!this.uploadedContractFiles.find((item) => item.contractFileId === doc.id && item.contractFileName === doc.name)) {
            this.uploadedContractFiles.push({
              contractFileId: doc.id,
              contractFileName: doc.name,
              contractFileCreatedAt: doc.createdAt,
              contractFileLoading: false
            });
          }
        }
      });
    }
  }

  downloadContractFile(contractFileId): void {
    const publisherId = this.parentFormControl.publisher_id ?
      this.parentFormControl.publisher_id.value : this.parentFormControl.publisherId.value;
    const selectedContractFile = this.uploadedContractFiles.find((file) => file.contractFileId === contractFileId);
    if (contractFileId > -1 && contractFileId != null) {
      this.publisherService.getFinancialDocumentFile(publisherId, contractFileId).subscribe(
        data => this.startDownload(data, selectedContractFile),
        (_) => {
          selectedContractFile.contractFileLoading = false;
        },
        () => {
          selectedContractFile.contractFileLoading = false;
        }
      );
    }
  }

  onContractFileChange(event: NzUploadChangeParam): void {
    
    if (!this.publisherData.payment) {
      this.publisherData.payment = {} as PublisherPayment;
    }
    if (this.contractHasBeenUploaded) {
      this.contractFile = [];
      this.contractHasBeenUploaded = false;
    }
    
    this.contractFile = this.contractFile.concat(event.file);
    if (event.file.status === FileStatus.DONE) {
      this.contractFileChange.emit();
    }
    
  }

  onContractFileRemove(file: NzUploadFile ) {
    this.contractFile = this.contractFile.filter(f => f.uid !== file.uid);
    this.contractFileChange.emit();
  }

  showApiErrorLargeFile(error, fileName: string): void {
    const title = fileName + ' was not saved';
    if (error.status === 400) {
      this.notification.error(title, error.error.message);
    }
  }

  /**
   *This function saves the contract file, it supposed to trigger from outside - for example with view child
   *@example
   *  `@ViewChild(UploadContractComponent) uploadContractComponent: UploadContractComponent`;
   *  and then:
   *  `uploadContractComponent.saveContractFileChangesPromise().then()`
   **/
  saveContractFileChanges(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.contractFile && this.contractFile.length > 0) {
        this.fileUploadService.uploadMultipleFiles(`${UPLOAD_FILE_URL()}/${this.publisherData.publisherId}/payment/financial-document`,
          this.contractFile, {docType: 'PAYMENT_INFO'})
          .then((res) => {
              const newFiles = res.map(x => x.body);
              this.publisherData.payment.publisherFinancialDocument = [
                ...(this.publisherData.payment?.publisherFinancialDocument || []), ...newFiles
              ];
              this.contractFile = [];
              this.contractHasBeenUploaded = true;
              this.calcContractFileUrl();
              resolve(true);
            },
            (error) => {
              this.showApiErrorLargeFile(error, 'Contract file');
              reject();
            }
          );
      } else {
        resolve(false);
      }
    });
  }
}
