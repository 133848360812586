import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

import { NzUploadFile } from 'ng-zorro-antd/upload';
import { cloneDeep } from 'lodash';
import { ClipboardService } from 'ngx-clipboard';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as fromPlacements from './_store/placements.reducer';
import * as PlacementsActions from './_store/placements.actions';
import { publishersPermissionsConfig } from '../supply/publishers/_services/publishers.config';
import { SharedService } from '../../shared/_services/shared.service';
import { AppFilter, AppTable, AuthPermissions, CommonDataModel, PlacementStatus, PortalType, UploadFileType } from '../../shared/_models/models';
import {
  placementsFiltersConfig,
  placementsPermissionsConfig,
  placementsTableColumns,
  placementsTableConfig,
  placementsTableButtons
} from './_services/placements.config';
import { AuthenticationService } from '../../auth/_services/authentication.service';
import { PlacementsService } from './_services/placements.service';
import { ApiService } from '../../shared/_services/api.service';
import { environment } from '../../../environments/environment';
import { CSV_FILES } from 'src/app/shared/_constants/file-upload';


@Component({
  selector: 'app-placements',
  templateUrl: './placements.component.html',
  styleUrls: ['./placements.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlacementsComponent implements OnInit, OnDestroy {

  // @Input() pubTsId: number;
  private _pubTsId: number;
  @Input()
  set pubTsId(pubTsId) {
    if (this._pubTsId !== pubTsId) {
      this._pubTsId = pubTsId;
      if (this._pubTsId) {
        this.getPlacements();
      }
    }
  }
  get pubTsId() {
    return this._pubTsId;
  }

  @Input() tsId: number;
  @Input() publisherId: number;
  @Input() portalType: PortalType;
  @Input() pubTsName: string;

  private unsubscribe$ = new Subject<void>();
  authPermissions: AuthPermissions;
  publisherAuthPermissions: AuthPermissions;
  placements$: Observable<CommonDataModel>;
  placementsTable$: Observable<AppTable>;
  placementsTable: AppTable = {...placementsTableConfig};
  placementsFilters$: Observable<AppFilter[]>;
  placementsFilters: AppFilter[] = cloneDeep(placementsFiltersConfig);
  placementsTableColumns = cloneDeep(placementsTableColumns);
  placementsTableButtons = cloneDeep(placementsTableButtons);

  placementBulkUrl: string;
  placementBulkUploadModalShow = false;
  placementBulkUploadLastUploadId: number;
  placementBulkUploadSearch = '';
  placementBulkUploadErrors: any[] = [];
  placementBulkUploadStatus = '';

  
  fileTypes: UploadFileType[] = CSV_FILES;


  constructor(
    private placementsService: PlacementsService,
    private router: Router,
    private clipboardService: ClipboardService,
    private auth: AuthenticationService,
    private store: Store<fromRoot.State>,
    private sharedService: SharedService,
    private api: ApiService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.authPermissions = this.auth.getPermissionsList(placementsPermissionsConfig);
    this.publisherAuthPermissions = this.auth.getPermissionsList(publishersPermissionsConfig);
    if (this.portalType === 'portal') {
      this.authPermissions = {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
      };

      // remove bulk placement from external view
      const bulkButtonIndex = this.placementsTableButtons.findIndex(b => b.id === 'bulk_create_placement_btn');
      if (bulkButtonIndex > -1) {
        this.placementsTableButtons.splice(bulkButtonIndex, 1);
      }
    }

    this.placementBulkUrl = environment.javaApiUrl + `/placement/bulk/${this.pubTsId}`;

    this.initSubscriptions();
    // this.initRequests();
  }

  initSubscriptions() {
    this.placements$ = this.store.select(fromPlacements.getPlacements);

    this.placementsFilters$ = this.store.select(fromPlacements.getPlacementsFilters);
    this.placementsFilters$.pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: AppFilter[]) => {
        this.placementsFilters = cloneDeep(res);
      });

    // == table listener and react on change ==
    this.placementsTable$ = this.store.pipe(select(fromPlacements.getPlacementsTable));

    this.placementsTable$.pipe(takeUntil(this.unsubscribe$), skip(1))
      .subscribe((res: AppTable) => {
        this.placementsTable = cloneDeep(res);
        this.getPlacements();
      });
  }

  // initRequests() {
  //   this.getPlacements();
  // }

  getPlacements() {
    let queryPlacements = this.sharedService.buildTableQuery(this.placementsFilters, this.placementsTable,
      ['placementInfo', 'placementTrafficSources']);
    if (!queryPlacements.filter) {
      queryPlacements = {...queryPlacements, filter: {filters: []}};
    }
    queryPlacements.filter.filters.push({
      fieldName: 'pubTsId',
      operation: 'EQUALS',
      value: this.pubTsId
    });
    this.placementsService.getPlacements(queryPlacements);
  }

  onTableSortChange(table: AppTable) {
    table.sortDirection = table.sortDirection.toUpperCase();
    table.pageIndex = 1;
    this.store.dispatch(new PlacementsActions.UpdatePlacementsTableAction({placementsTable: {...table}}));
  }

  onTablePageChange(table: AppTable) {
    this.store.dispatch(new PlacementsActions.UpdatePlacementsTableAction({placementsTable: {...table}}));
  }

  onFiltersChange(event: { filters: AppFilter[]; filterId: string }, isResetFilters = false) {
    let newFilters: AppFilter[];
    if (isResetFilters) {
      newFilters = this.sharedService.resetFilterSelectedValues(this.placementsFilters);
    } else {
      newFilters = event.filters;
    }

    this.placementsTable.pageIndex = 1;
    if (!this.placementsTable.sortBy) {
      this.placementsTable.sortBy = 'createdAt';
      this.placementsTable.sortDirection = 'DESC';
    }
    this.store.dispatch(new PlacementsActions.UpdateFiltersAction({placementsFilters: cloneDeep(newFilters)}));
    this.store.dispatch(new PlacementsActions.UpdatePlacementsTableAction({placementsTable: cloneDeep(this.placementsTable)}));
  }

  onFilterSearch(event: { searchValue: string; filterId: string }) {
    switch (event.filterId) {
      default:
        break;
    }
  }

  onRowAction(event: any) {
    const placement = event.row;
    let editRoute: string;
    if (this.portalType === 'supply') {
      editRoute = !this.publisherAuthPermissions.canUpdate ? `/supply/publishers/${this.publisherId}/view/${this.pubTsId}/placements/${placement.placementId}` :
        `/supply/publishers/${this.publisherId}/update/${this.pubTsId}/placements/${placement.placementId}`;
    } else {
      editRoute = `/portal/integration-details/${this.pubTsId}/placements/${placement.placementId}`;
    }
    switch (event.action) {
      case 'placementStatusToggle':
        const placementStatus  = {status: placement.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'} as PlacementStatus;
        this.placementsService.updatePlacementStatus(placement.placementId, placementStatus);
        break;
      case 'placementEdit':
        this.router.navigate([editRoute + '/update']);
        break;
      case 'placementDuplicate':
        this.router.navigate([editRoute + '/duplicate']);
        break;
      case 'placementView':
        this.router.navigate([editRoute + '/view']);
        break;
    }
  }

  onButtonClick(buttonId: string) {
    switch (buttonId) {
      case 'placementsResetFiltersButton':
        this.onFiltersChange(null, true);
        break;
      case 'bulk_create_placement_btn':
        this.placementBulkUploadModalShow = true;
        break;
      case 'create_placement_btn':
        const createRoute = this.portalType === 'portal' ?
          `/portal/integration-details/${this.pubTsId}/placements/create` :
          `/supply/publishers/${this.publisherId}/update/${this.pubTsId}/placements/create`;

        this.router.navigate([createRoute]);
        break;
      default:
        break;
    }
  }

  onTagHelpCenterClick() {
    const tagHelpCenterUrl = 'https://ssp-help.unruly.co/hc/en-us/articles/30479668612627-Supported-Tag-Parameters';
    window.open(tagHelpCenterUrl, '_blank');
  }

  public onBulkPlacementUploadFinished(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        // Do Nothing for now
        break;
      case 'done':
        const uploadId = info.file.response.data.id;
        this.placementBulkUploadLastUploadId = uploadId;
        this.placementBulkUploadSearch = uploadId;
        this.getBulkUploadStatus();
        this.sharedService.showNotification('success', 'File uploaded successfully. Upload ID:' + uploadId);
        break;
      case 'error':
        this.sharedService.showNotification('error', 'File upload failed');
        break;
    }
  }

  public getBulkUploadStatus(): void {

    // avoid empty id request
    if (!this.placementBulkUploadSearch) {
      return;
    }
    const uploadId = +this.placementBulkUploadSearch;
    this.placementBulkUploadErrors = [];
    this.api.getBulkUploadStatus(uploadId).subscribe(response => {
      this.placementBulkUploadStatus = response.status;
      if (response.errors && response.errors !== '[""]') {
        const parsedArr = response.errors.split('|');
        this.placementBulkUploadErrors = parsedArr.reduce((res, val) => {
          if (val) {
            res.push(JSON.parse(val));
          }
          return res;
        }, []);
      }
    }, () => {
      this.placementBulkUploadStatus = 'Not found';
      this.sharedService.showNotification('error', 'Error', 'Bulk upload check status for id ' + uploadId + ' failed');
      this.cdr.detectChanges();
    }, () => {
      this.cdr.detectChanges();
    });
  }

  getErrorText(err) {
    const message = `Row: ${err.row} | Error: ${err.message}`;
    return message;
  }

  public closePlacementBulkUploadModal(): void {
    this.placementBulkUploadModalShow = false;
    this.placementBulkUploadSearch = '';
    this.placementBulkUploadErrors = [];
    this.placementBulkUploadStatus = '';
    this.placementBulkUploadLastUploadId = null;
    this.getPlacements();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    // reset store to initial table and filters state
    this.store.dispatch(new PlacementsActions.UpdateFiltersAction({placementsFilters: cloneDeep(placementsFiltersConfig)}));
    this.store.dispatch(new PlacementsActions.UpdatePlacementsTableAction({placementsTable: {...placementsTableConfig}}));
  }
}
