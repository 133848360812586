import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, takeUntil, tap } from 'rxjs/operators';

import * as _ from 'lodash';
import { assign, cloneDeep, findIndex } from 'lodash';
import { NzUploadFile } from 'ng-zorro-antd/upload';

import {
  AppFilter,
  AppTable,
  AppTableColumn, AudienceTargetingGroup,
  AudienceTargetingSegment,
  AuthPermissions,
  DomainBundle,
  DomainBundlePayload,
  DomainFilter,
  InventoryPackage,
  JapiQuery, PadAgreement, PadAgreementAdomainPayload,
  Placement,
  PublisherMedia,
  PublisherTrafficSource, ResponseFromServer,
  RxSegmentMapPayload,
  SegmentType,
  ThinPublisher,
  UploadFileType,
  UserType
} from '../../_models/models';
import { AuthenticationService } from '../../../auth/_services/authentication.service';
import { DomainBundlesService } from './_services/domain-bundles.service';
import { SharedService } from '../../_services/shared.service';
import {
  domainBundlesChangesFiltersConfig,
  domainBundlesChangesTableColumns,
  domainBundlesChangesTableConfig,
  domainBundlesFiltersConfig,
  domainBundlesPermissionsConfig,
  domainBundlesTableButtons,
  domainBundlesTableColumns,
  domainBundlesTableColumnsRx,
  domainBundlesTableConfig,
  exchangePadDomainBundlesFiltersConfig,
  exchangePadDomainBundlesTableButtons,
  exchangePadDomainBundlesTableColumns,
  exchangePadDomainBundlesTableConfig,
  exchangePadInitialQueryConfig,
  initialSegmentMapsFilterQueryConfig
} from './_services/domain-bundles.config';
import { ApiService } from '../../_services/api.service';
import { CreateQueryParams, RequestQueryBuilder } from '@libs/crud-request-nestjs';
import { SupplyPublisherService } from '../../../features/supply/publishers/_services/publisher.service';
import { Common } from '../../_common/common.helper';
import { FileStatus, ValidationResult } from '../upload-file/upload-file.component';
import { CSV_FILES } from '../../_constants/file-upload';

@Component({
  selector: 'app-domain-bundles',
  templateUrl: './domain-bundles.component.html',
  styleUrls: ['./domain-bundles.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainBundlesComponent implements OnInit, OnDestroy {
  @Input() rxSegmentMapPayload: RxSegmentMapPayload;
  @Input() editMode: 'CREATE' | 'DUPLICATE' | 'EDIT';
  @Input() parentType: 'TRAFFIC-SOURCE' | 'PLACEMENT' | 'INVENTORY-PACKAGE' | 'RX-DEAL' | 'PAD_AGREEMENT';
  @Input() parent: Placement | InventoryPackage | PublisherTrafficSource | AudienceTargetingSegment | PadAgreement;
  @Input() pubTsId: number;
  @Input() groups: AudienceTargetingGroup[];
  @Output() updatedDomainBundleFilter: EventEmitter<DomainFilter> = new EventEmitter();
  @Output() domainBundleFileUpdated: EventEmitter<NzUploadFile[]> = new EventEmitter();
  @Output() domainBundleFileRemoved: EventEmitter<void> = new EventEmitter();
  @Output() domainBundlePayloadUpdated: EventEmitter<DomainBundlePayload> = new EventEmitter();
  @Output() padAgreementAdomainPayloadUpdated: EventEmitter<PadAgreementAdomainPayload> = new EventEmitter();

  placementId: number;
  isDestroyed = false;
  selectIcons: Record<string, boolean> = {};
  authPermissions: AuthPermissions;
  uploadedFile: NzUploadFile[] = [];
  domainBundleTotalElements: number;
  domainBundleDBTotalElements: number;
  domainBundles: DomainBundle[] = [];
  domainBundleChangesBeforeFileUpload: {value: string; type: string; change: string }[] = [];
  domainBundleChanges: { value: string; type: string; change: string }[] = [];
  domainBundleFilteredChanges: { value: string; type: string; change: string }[] = [];
  domainBundlePayload: DomainBundlePayload = {
    addedDomainBundles: [], removedDomainBundles: [], updatedDomainBundles: [],
    removeAllDomains: false
  } as DomainBundlePayload;
  isDomainsLoading = false;
  activeTab: number;
  domainBundlesForm: UntypedFormGroup;
  domainBundlesTable: AppTable = {...domainBundlesTableConfig};
  domainBundlesChangesTable: AppTable = {...domainBundlesChangesTableConfig};
  domainBundlesFiltersConfig: AppFilter[] = cloneDeep(domainBundlesFiltersConfig);
  domainBundleChangesFiltersConfig: AppFilter[] = cloneDeep(domainBundlesChangesFiltersConfig);
  domainBundlesTableColumns: AppTableColumn[] = domainBundlesTableColumns;
  domainBundlesChangesTableColumns = domainBundlesChangesTableColumns;
  domainBundlesTableButtons = cloneDeep(domainBundlesTableButtons);
  userType: UserType;
  segmentMapsFilterQueryConfig: CreateQueryParams = _.cloneDeep(initialSegmentMapsFilterQueryConfig);
  padAgreementDomainsFilterQueryConfig: CreateQueryParams = _.cloneDeep(exchangePadInitialQueryConfig);
  isTableLoading = false;
  formTitle = '';
  isPublishersLoading = false;
  segmentPublishers: string[];
  listOfPublishers$: Observable<ThinPublisher[]> | null;
  fileTypes: UploadFileType[] = [ ...CSV_FILES, UploadFileType.TEXT_PLAIN ];
  private parsedUploadedFile: string[][];

  private unsubscribe$ = new Subject<void>();
  private segmentId: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private auth: AuthenticationService,
    private domainBundlesService: DomainBundlesService,
    private sharedService: SharedService,
    private api: ApiService,
    private cdr: ChangeDetectorRef,
    private publishersService: SupplyPublisherService,
    private common: Common,
  ) {
  }

  @Input() set segmentType(value: string) {
    if (value === 'mediaid') {
      setTimeout(() => {
        const validators = value ? [this.mediaIdValidator()] : [];
        this.domainBundlesForm.controls.addDomainBundles.setValidators(validators);
        this.domainBundlesForm.controls.addDomainBundles.updateValueAndValidity({onlySelf: true});
      });
    } else {
      setTimeout(() => {
        this.domainBundlesForm.controls.addDomainBundles.setValidators(null);
        this.domainBundlesForm.controls.addDomainBundles.updateValueAndValidity({onlySelf: true});
      });
    }
    this.setFormTitle(value);
  }

  ngOnInit(): void {
    this.userType = this.auth.currentUserValue.type;
    this.authPermissions = this.auth.getPermissionsList(domainBundlesPermissionsConfig);
    if (this.parentType === 'RX-DEAL') {
      this.domainBundlesTable.pageSize = this.segmentMapsFilterQueryConfig.limit;
      this.domainBundlesChangesTable.pageSize = this.segmentMapsFilterQueryConfig.limit;
      this.domainBundlesTableColumns = domainBundlesTableColumnsRx;
      this.getSegmentPublishers();
    }

    this.uploadedFile = [];
    // == override permissions for external user
    if (this.userType !== 'INTERNAL' || this.parentType === 'RX-DEAL') {
      this.authPermissions = {
        canRead: true,
        canCreate: true,
        canUpdate: true,
        canDelete: true,
      };
    }
    if (this.parentType !== 'RX-DEAL') {
      const createdAtColumnIndex = _.findIndex(this.domainBundlesTableColumns, ['id', 'domainBundlesCreatedAt']);
      if (this.editMode !== 'CREATE') {
        this.placementId = this.parentType === 'PLACEMENT' ? (this.parent as Placement).placementId : 0;
        (this.parent as Placement | InventoryPackage | PublisherTrafficSource).filterDomainBundle =
          this.parent ? (this.parent as Placement | InventoryPackage | PublisherTrafficSource).filterDomainBundle : 'DISABLED';
        this.domainBundlesTableColumns[createdAtColumnIndex].isHidden = false;
      } else {
        this.domainBundlesTableColumns[createdAtColumnIndex].isHidden = true;
      }
    }

    if (this.parentType === 'PAD_AGREEMENT') {
      this.domainBundlesFiltersConfig = cloneDeep(exchangePadDomainBundlesFiltersConfig);
      this.domainBundlesTableButtons = cloneDeep(exchangePadDomainBundlesTableButtons);
      this.domainBundlesTable = {...exchangePadDomainBundlesTableConfig};
      this.domainBundlesTableColumns = cloneDeep(exchangePadDomainBundlesTableColumns);
      this.domainBundlesChangesTableColumns = cloneDeep(domainBundlesChangesTableColumns);
      this.domainBundlesForm?.controls.filterDomainBundle?.setValue('DISABLED');
      // this.setFormTitle('PAD_AGREEMENT');
    }

    // remove delete button
    if (this.editMode !== 'EDIT' && this.editMode !== 'CREATE') {
      this.domainBundlesTableButtons = [];
      this.domainBundlesTableColumns = this.sharedService.removeFromArrayOfObjectsByProp(this.domainBundlesTableColumns, 'id',
        ['domainBundlesActions', 'domainBundlesSubdomain']);
    }
    this.activeTab = 0;
    this.initForm();
    this.initFormListenChanges();
    this.initSubscriptions();
    this.setFilterButtonValues('deleteAll', {isDisabled: this.editMode === 'CREATE'});
    this.setFilterButtonValues('download', {isDisabled: this.editMode === 'CREATE'});
    this.setFormTitle();
  }

  setFormTitle(value: string = this.segmentType): void {
    if (this.parentType === 'PAD_AGREEMENT') {
      value = this.parentType;
    }
    switch (value) {
      case 'mediaid':
        this.formTitle = 'Add App IDs or Site IDs Manually';
        break;
      case 'pubid':
        this.formTitle = 'Add Publishers manually';
        break;
      case 'PAD_AGREEMENT':
        this.formTitle = 'Add Domains Manually';
        break;
      default:
        this.formTitle = 'Add site / app bundles manually';
        break;
    }
  }

  getSegmentPublishers(): void {
    this.segmentPublishers = [];
    const segmentPublisherIds: number[] = [];
    this.groups.forEach(group => {
      group?.segments.forEach(s => {
        if (s.type === 'pubid' && s?.segmentMaps && s.segmentMaps.length) {
          // get new ui selected segments
          this.segmentPublishers = s.segmentMaps;
        }
        if (s.type === 'pubid') {
          // save segment ids to fetch from db
          segmentPublisherIds.push(s.segmentId);
        }
      });
    });
    if (segmentPublisherIds.length === 0) {
      return;
    }
    const publisherMediaPayload: PublisherMedia = {segmentIds: segmentPublisherIds, limit: undefined, page: 1};
    this.api.getPublisherSegmentMedia(publisherMediaPayload).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      if (res.data) {
        const dbSegmentPublishers = res.data.map((s: any) => s.matchingValue);
        this.segmentPublishers = this.segmentPublishers.concat(dbSegmentPublishers);
      }
    });
  }

  mediaIdValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      const values = control.value as string[];
      if (!values || values.length === 0 || values.every(value => value.trim().length === 0)) {
        return null;
      }
      let error = null;

      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        const parts = value.split('||');

        if (parts.length !== 2) {
          error = {mediaId: 'Format should be "sspid||mediaid"'};
          break;
        }

        const ssp = parts[0].trim();
        const mediaId = parts[1].trim();

        if (!ssp) {
          error = {mediaId: 'Ssp required'};
          break;
        }

        if (!mediaId) {
          error = {mediaId: 'Media ID required'};
          break;
        }
      }
      return error;
    };
  }

  rxUpdatePayload() {
    if (this.rxSegmentMapPayload) {
      if (this.rxSegmentMapPayload.addedSegmentMaps?.length > 0) {
        this.domainBundlesForm.controls.addDomainBundles.setValue(this.rxSegmentMapPayload.addedSegmentMaps);
        this.onDomainBundlesAdd();
      }
      if (this.rxSegmentMapPayload.removedSegmentMaps?.length > 0) {
        this.rxSegmentMapPayload.removedSegmentMaps.forEach(domainBundle => {
          const row = {domainBundle};
          this.onDeleteDomainBundle({row});
        });
      }
      if (this.rxSegmentMapPayload.removeAllSegmentMaps) {
        this.onDeleteAllClicked();
      }
    }
  }

  initSubscriptions(): void {
    if (this.editMode !== 'CREATE') {
      this.getDomainBundles();
    }
    if ((this.parent as AudienceTargetingSegment)?.segmentMaps?.length > 0) {
      const segMap = (this.parent as AudienceTargetingSegment).segmentMaps;
      this.domainBundlesForm.controls.addDomainBundles.setValue(segMap);
      this.onDomainBundlesAdd();
    }
  }

  getPadAgreementDomains(padId: number, query: JapiQuery) {
    const searchValue = query.filter?.filters[0]?.value as string;
    if (searchValue) {
      this.padAgreementDomainsFilterQueryConfig.search = {
        adomain: {$contL: `${searchValue}`}
      };
    } else {
      this.padAgreementDomainsFilterQueryConfig.search = {};
    }

    if (query.sorting?.sortingField) {
      this.padAgreementDomainsFilterQueryConfig.sort = [{
        field: 'adomain',
        order: query.sorting.sortDirection
      }];
    }

    this.padAgreementDomainsFilterQueryConfig.page = query.paging?.number || 1;
    this.padAgreementDomainsFilterQueryConfig.limit = query.paging?.size || this.domainBundlesTable.pageSize;

    const filter = RequestQueryBuilder.create(this.padAgreementDomainsFilterQueryConfig).query();

    return this.api.getPadAgreementDomains(padId, filter).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      this.isTableLoading = res.isLoading;
      if (res.status === 'Success') {
        this.domainBundles = res.data?.map(domain => ({domainBundle: domain} as DomainBundle));
        this.domainBundleTotalElements = res?.totalElements;
        this.onDomainBundlesUpdate();
      }
    });
  }

  reloadSegmentMap(query: JapiQuery, segmentTypeName: string): void {
    const searchValue = query.filter?.filters[0]?.value as string;
    if (searchValue) {
      this.segmentMapsFilterQueryConfig.search = {matchingValue: {$contL: '%' + searchValue + '%'}};
    } else {
      this.segmentMapsFilterQueryConfig.search = {};
    }
    if (query.sorting.sortingField) {
      this.segmentMapsFilterQueryConfig.sort = [{
        field: 'matchingValue',
        order: query.sorting.sortDirection
      }];
    }
    this.segmentMapsFilterQueryConfig.page = query.paging?.number || 1;
    this.segmentMapsFilterQueryConfig.limit = query.paging?.size || 5;
    const filter = RequestQueryBuilder.create(this.segmentMapsFilterQueryConfig).query();
    if (segmentTypeName !== SegmentType.pubid) {
      this.api.getSegmentMedia(this.segmentId, filter).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(res => {
        this.isTableLoading = res.isLoading;
        if (res.status === 'Success') {
          this.domainBundles = res.data?.map(domainBundle => ({domainBundle} as DomainBundle));
          this.domainBundleTotalElements = res?.totalElements;
          this.onDomainBundlesUpdate();
          this.rxUpdatePayload();
        }
      });
    } else {
      const page = this.domainBundlesTable?.pageIndex ? this.domainBundlesTable?.pageIndex : 1;
      const publisherMediaPayload: PublisherMedia = {
        segmentIds: [this.segmentId],
        page: page,
        limit: this.segmentMapsFilterQueryConfig.limit,
      };
      this.isTableLoading = true;
      this.api.getPublisherSegmentMedia(publisherMediaPayload).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(res => {
        if (res.data) {
          const publisherIds = res.data;
          this.domainBundleTotalElements = res.totalElements;
          this.getPublishersAndStatus(publisherIds);
        }
      });
    }
  }

  getPublishersAndStatus(pubIdsList): void {
    if (this.parentType === 'RX-DEAL') { // if the parentType is 'RX-DEAL', publisher ids are not validated or fetched the entire object
      this.domainBundles = pubIdsList.map((pubId) => ({domainBundle: String(pubId)} as DomainBundle));
      this.onDomainBundlesUpdate();
      this.rxUpdatePayload();
      this.isTableLoading = false;
    } else {
      const query = this.domainBundlesService.buildSearchPublishersByIdsQuery(pubIdsList);
      this.publishersService.getFilteredPublishers(query)
        .pipe(
          takeUntil(this.unsubscribe$),
          map((res) => {
            const domainBundlesList: DomainBundle[] = [];
            res.data.forEach(item => {
              const bundleObject = item.status === 'INACTIVE' ?
                ({domainBundle: `${item.publisherId}  (Inactive)`} as DomainBundle)
                : ({domainBundle: String(item.publisherId)} as DomainBundle);
              domainBundlesList.push(bundleObject);
            });
            this.domainBundles = domainBundlesList;
            this.onDomainBundlesUpdate();
            this.rxUpdatePayload();
          }),
          finalize(() => {
            this.isTableLoading = false;
          })).subscribe();
    }
  }

  getDomainBundles(): void {
    const query = this.buildQuery();
    const parentId = this.parentType === 'INVENTORY-PACKAGE' ? (this.parent as InventoryPackage)?.packageId :
      this.parentType === 'PLACEMENT' ? (this.parent as Placement)?.placementId : (this.parent as PublisherTrafficSource)?.pubTsId;
    if (parentId) {
      this.api.getDomainBundles(this.parentType, query).pipe(takeUntil(this.unsubscribe$))
        .subscribe((res) => {
          this.domainBundles = res?.data;
          this.domainBundleTotalElements = res?.totalElements;
          this.domainBundleDBTotalElements ??= res?.totalElements;
          this.onDomainBundlesUpdate();
        },
        () => {
          this.sharedService.showNotification('error', 'get Domain bundles failed', 'Could not get domain bundles');
        });
    }
    const segmentId = (this.parent as AudienceTargetingSegment)?.segmentId;
    if (segmentId) {
      this.segmentId = segmentId;
      const segmentTypeNameTemp = ('segmentTypeName' in this.parent) ? this.parent.segmentTypeName : '';
      this.reloadSegmentMap(query, segmentTypeNameTemp);
    }

    const padId = (this.parent as PadAgreement)?.paId;
    if (padId) {
      this.getPadAgreementDomains(padId, query);
    }
  }

  onDomainBundlesUpdate(): void {
    if (this.domainBundlePayload) {
      if (this.domainBundlePayload.updatedDomainBundles.length > 0) {
        this.domainBundlePayload.updatedDomainBundles.forEach(updatedDomain => {
          const index = _.findIndex(this.domainBundles, ['domainBundle', updatedDomain.domainBundle]);
          if (index !== -1) {
            this.domainBundles[index].includeSubDomains = updatedDomain.includeSubDomains;
            this.domainBundles[index].changes = 'To be updated';
          }
        }
        );
      }
      if (this.domainBundlePayload.removedDomainBundles.length > 0) {
        this.domainBundlePayload.removedDomainBundles.forEach(removedDomain => {
          const index = _.findIndex(this.domainBundles, ['domainBundle', removedDomain.domainBundle]);
          if (index !== -1) {
            this.domainBundles[index].changes = 'To be deleted';
          }
        }
        );
      }
    }
    this.setFilterButtonValues('download', {isDisabled: this.isDownloadDisabled()});
    this.setFilterButtonValues('deleteAll', {isDisabled: this.domainBundleDBTotalElements === 0});
    this.validateDomainBundleFilter();
  }

  downloadToCsv(): void {
    if (this.parentType === 'RX-DEAL') {
      this.downloadRxDealDomains();
      return;
    }

    const parentInfo = this.getParentInfo();
    if (!parentInfo.id) {
      return;
    }

    this.downloadParentDomains(parentInfo);
  }


  isDownloadDisabled(): boolean {
    if (this.editMode === 'EDIT') {
      return this.domainBundleDBTotalElements === 0;
    }
    return true;
  }

  initForm() {
    this.domainBundlesForm = this.formBuilder.group({
      filterDomainBundle: this.parent && this.parentType !== 'RX-DEAL' && this.parentType !== 'PAD_AGREEMENT' ?
        (this.parent as Placement | InventoryPackage | PublisherTrafficSource).filterDomainBundle : 'DISABLED',
      includeSubDomains: false,
      addDomainBundles: [null],
      addPublishers: [null],
      includePublishers: false,
    });
  }

  initFormListenChanges(): void {
    this.domainBundlesForm.valueChanges.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.sendOutputsToParent();
      });

    // == domain filter cannot be a whitelist or blacklist if no domains exist
    this.domainBundlesForm.controls.filterDomainBundle.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.validateDomainBundleFilter();
      });
  }

  getFilteredPublishers(query: JapiQuery): Observable<ResponseFromServer<ThinPublisher[]>> {
    return this.api.getPublishers(query);
  }

  searchPublishers(value: string): void {
    if (value.trim().length === 0) {
      this.isPublishersLoading = false;
      return;
    }
    this.isPublishersLoading = true;
    const query = this.domainBundlesService.buildSearchPublishersQuery(value);
    this.listOfPublishers$ = this.publishersService.getFilteredPublishers(query)
      .pipe(
        takeUntil(this.unsubscribe$),
        map((res) => {
          this.isPublishersLoading = false;
          const publisherMediaList: ThinPublisher[] = [];
          res.data.forEach(item => {
            let disabled = false;
            if (this.segmentPublishers && this.segmentPublishers.length > 0) {
              disabled = this.segmentPublishers.includes(item.publisherId.toString());
            }
            const publisherObject = {...item, disabled: disabled};
            publisherMediaList.push(publisherObject);
          });
          return publisherMediaList;
        }));
  }

  onTabChanged(activeTab: number): void {
    this.activeTab = activeTab;
  }

  parsingCsvValidation = (file: NzUploadFile): Observable<ValidationResult> => {
    if (this.parentType !== 'RX-DEAL' && this.parentType !== 'PAD_AGREEMENT') {
      return of({isValid: true} as ValidationResult);
    }
    return this.common.parseCSV<string[]>(file as unknown as File, false, true, ['|'])
      .pipe(
        tap(data => this.parsedUploadedFile = data),
        map(_data => ({ isValid: true } as ValidationResult)),
        catchError(_error => of({
          isValid: false,
          errorMessage: 'Error parsing CSV file'
        } as ValidationResult))
      );
  };

  handleRemoveFile = (file: NzUploadFile): boolean => {
    if (this.parentType === 'RX-DEAL' || this.parentType === 'PAD_AGREEMENT') {
      this.domainBundlePayload.addedDomainBundles = this.domainBundleChangesBeforeFileUpload
        .filter(change => change.change === 'To be added')
        .map(change => ({
          id: null,
          domainBundle: change.value,
          includeSubDomains: this.domainBundlesForm.controls.includeSubDomains.value,
          createdAt: null,
          updatedAt: null
        } as DomainBundle));
      this.domainBundlePayload.removedDomainBundles = [];
      this.domainBundlePayload.updatedDomainBundles = [];
    }
    this.revertUploadedFileChanges();
    this.revertChanges(file.name);
    this.revertDomainBundleChanges();
    this.sendOutputsToParent();
    return false;
  };

  validateDomainBundleFilter(): void {
    // Skip validation for PAD_AGREEMENT since filtering is always disabled
    if (this.parentType === 'PAD_AGREEMENT') {
      return;
    }

    const currentFilter = this.domainBundlesForm.controls.filterDomainBundle;
    if (currentFilter.untouched && currentFilter.value !== 'DISABLED') {
      currentFilter.markAsTouched();
    }
    currentFilter.setErrors((currentFilter.value !== 'DISABLED' && this.isDomainsListEmpty()) ? {'notAllowed': true} : null);
    currentFilter.markAsDirty();
    this.domainBundlesForm.updateValueAndValidity({emitEvent: true});
  }
  isDomainsListEmpty(): boolean {
    return (
      // Check if there are no domain bundles in the database, or if in create mode and no domains were added:
      ((this.domainBundleDBTotalElements === 0 || this.editMode === 'CREATE') &&
        (!this.domainBundlePayload || (this.domainBundlePayload && this.domainBundlePayload.addedDomainBundles.length === 0)) &&
        (this.uploadedFile && this.uploadedFile.length === 0 || !this.uploadedFile)) ||

      // Check if there are domain bundles in the database but all domains were removed manually or with the "remove all" button,
      // and no domains were added:
      ((this.domainBundleDBTotalElements > 0) &&
        ((this.domainBundlePayload.removeAllDomains) &&
          (!this.domainBundlePayload || (this.domainBundlePayload && this.domainBundlePayload.addedDomainBundles.length === 0)) &&
          (this.uploadedFile && this.uploadedFile.length === 0 || !this.uploadedFile))) ||

      // Check if all domains in the database were marked to be removed manually:
      (this.domainBundlePayload && this.domainBundlePayload.removedDomainBundles.length > 0 &&
        this.domainBundlePayload.removedDomainBundles.length === this.domainBundleDBTotalElements)
    );
  }

  sendOutputsToParent(): void {
    const domainFilterToEmit = this.createDomainFilter();
    this.updatedDomainBundleFilter.emit(domainFilterToEmit);
    this.domainBundlePayloadUpdated.emit(this.domainBundlePayload);
  }

  onFiltersChange(event: { filters: AppFilter[]; filterId: string }): void {
    if (event.filterId === 'domainBundlesSearchNameFilter') {
      this.domainBundlesFiltersConfig = cloneDeep(event.filters);
      this.getDomainBundles();
      this.domainBundlesTable.pageIndex = 1;
      if (!this.domainBundlesTable.sortBy) {
        this.domainBundlesTable.sortBy = 'domainBundle';
        this.domainBundlesTable.sortDirection = 'ASC';
      }
    } else {
      this.domainBundleChangesFiltersConfig = cloneDeep(event.filters);
      this.domainBundleFilteredChanges = this.sharedService.filterClientSideTable(this.domainBundleChangesFiltersConfig,
        this.domainBundleChanges);
    }
  }

  hasSelectionsInFilters(): boolean {
    return this.domainBundleChangesFiltersConfig?.some(f => f.selectedValues?.length > 0);
  }

  onRowAction(event: any): void {
    switch (event.action) {
      case 'domainBundlesIncludeSubDomains':
        this.onIncludeSubdomainsChanged(event);
        break;
      case 'domainBundleDelete':
        this.onDeleteDomainBundle(event);
        break;
    }
  }

  onRowChangesAction(event: any): void {
    switch (event.action) {
      case 'cancelAction':
        this.onCancelActionClicked(event);
        break;
    }
  }

  onDeleteDomainBundle(event: { row: { id?: number; domainBundle: string; includeSubDomains?: boolean } }): void {
    // if domain was updated and then removed - first will revert changes and then make the updates
    const domainToUpdate = this.domainBundles.find(d => d.domainBundle === event.row.domainBundle);
    if (this.domainBundlePayload.updatedDomainBundles.find(d => d.id === event.row.id)) {
      this.revertUpdatedDomainChanges(domainToUpdate, event.row.domainBundle);
      domainToUpdate.includeSubDomains = !event.row.includeSubDomains;
    }
    // if domain is removed for the first time
    let alreadyDeletedDomain;
    if (this.parentType === 'RX-DEAL') {
      alreadyDeletedDomain = this.domainBundlePayload.removedDomainBundles.some(d => d.domainBundle === event.row.domainBundle);
    } else {
      alreadyDeletedDomain = this.domainBundlePayload.removedDomainBundles.some(d => d.id === event.row.id);
    }
    if (!alreadyDeletedDomain) {
      this.makeDeletedDomainChanges(domainToUpdate, event);
    }
    this.validateDomainBundleFilter();
  }

  onIncludeSubdomainsChanged(event: any): void {
    // if value was already changed before, avoid duplications
    const alreadyUpdatedDomain = this.domainBundlePayload.updatedDomainBundles.find(d => d.id === event.row.id);
    const domainToUpdate = this.domainBundles.find(d => d.domainBundle === event.row.domainBundle);
    if (alreadyUpdatedDomain) {
      this.revertUpdatedDomainChanges(domainToUpdate, event.row.domainBundle);
      return;
    }
    // if value was removed and then changed undo the remove action
    if (this.domainBundlePayload.removedDomainBundles.find(d => d.id === event.row.id)) {
      this.revertDeletedDomainChanges(domainToUpdate, event.row.domainBundle);
    }
    this.makeIncludeSubDomainUpdates(domainToUpdate, event);
  }

  revertDeletedDomainChanges(domainToUpdate: DomainBundle, domainBundle: string): void {
    domainToUpdate.changes = '';
    this.revertChanges(domainBundle);
    this.domainBundlePayload.removedDomainBundles = this.domainBundlePayload.removedDomainBundles
      .filter(column => column.domainBundle !== domainBundle);
  }

  revertUpdatedDomainChanges(domainToUpdate: DomainBundle, domainBundle: string): void {
    this.revertChanges(domainBundle);
    this.domainBundlePayload.updatedDomainBundles = this.domainBundlePayload.updatedDomainBundles
      .filter(column => column.domainBundle !== domainBundle);
    domainToUpdate.includeSubDomains = !domainToUpdate.includeSubDomains;
    domainToUpdate.changes = '';
  }

  revertAddedDomainChanges(domainToUpdate: DomainBundle, domainBundle: string, isFile: boolean): void {
    this.revertChanges(domainBundle);
    if (isFile) {
      this.revertUploadedFileChanges();
    } else {
      this.domainBundlePayload.addedDomainBundles = this.domainBundlePayload.addedDomainBundles
        .filter(column => column.domainBundle !== domainBundle);
    }
  }

  revertDeleteAllChanges(domainToCancelAction: DomainBundle, domainBundle: string): void {
    this.revertChanges(domainBundle);
    this.domainBundlePayload.removeAllDomains = false;
    this.domainBundlesTable.tableClass = '';
  }

  revertChanges(domainBundle: string): void {
    this.domainBundleChanges = this.domainBundleChanges.filter(column => column.value !== domainBundle);
    this.domainBundleFilteredChanges = this.domainBundleFilteredChanges?.filter(column => column.value !== domainBundle);
  }

  makeIncludeSubDomainUpdates(domainToUpdate: DomainBundle, event: any): void {
    const updatedDomain = cloneDeep(event.row);
    delete updatedDomain.changes;
    updatedDomain.includeSubDomains = !updatedDomain.includeSubDomains;
    this.domainBundlePayload.updatedDomainBundles = [...this.domainBundlePayload.updatedDomainBundles, updatedDomain];
    domainToUpdate.includeSubDomains = !domainToUpdate.includeSubDomains;
    domainToUpdate.changes = 'To be updated';
    this.domainBundleChanges = [...this.domainBundleChanges, {
      change: 'To be updated',
      value: event.row.domainBundle,
      type: 'domain bundle'
    }];
    this.activeTab = 1;
    this.sendOutputsToParent();
  }

  onCancelActionClicked(event: any): void {
    const domainToCancelAction = this.domainBundles.find(d => d.domainBundle === event.row.value);
    switch (event.row.change) {
      case 'To be deleted':
        if (event.row.value === 'entire domains list') {
          this.revertDeleteAllChanges(domainToCancelAction, event.row.value);
        } else {
          this.revertDeletedDomainChanges(domainToCancelAction, event.row.value);
        }
        break;
      case 'To be added': {
        const isFile = event.row.type === 'file';
        this.revertAddedDomainChanges(domainToCancelAction, event.row.value, isFile);
        break;
      }
      case 'To be updated':
        this.revertUpdatedDomainChanges(domainToCancelAction, event.row.value);
        break;
    }
    this.validateDomainBundleFilter();
    this.sendOutputsToParent();
  }

  onDomainBundlesAdd(values?: string[]): void {
    let domainBundlesToCreate: string[];
    if (values) {
      const invalidValue = this.parentType === 'RX-DEAL' && this.parent['type'] === 'mediaid'
        ? values.find(value => !value.match(/^\w+\|\|\w+$/g))
        : undefined;
      if (invalidValue) {
        this.sharedService.showNotification('error', 'Invalid format', `"${invalidValue}" is invalid. Format should be "sspid||mediaid"`);
        return;
      }
      domainBundlesToCreate = values;
    } else if (
      this.domainBundlesForm.controls.addDomainBundles.valid &&
      this.domainBundlesForm.controls.addDomainBundles.value &&
      this.domainBundlesForm.controls.addDomainBundles.value.length > 0
    ) {
      domainBundlesToCreate = this.domainBundlesForm.controls.addDomainBundles.value.filter(d => d.trim() !== '');
    }

    if (domainBundlesToCreate) {
      domainBundlesToCreate = _.uniqWith(domainBundlesToCreate.map(d => d.toLowerCase()), _.isEqual);

      this.createMedia(domainBundlesToCreate);
      this.sendOutputsToParent();
      this.validateDomainBundleFilter();
      this.domainBundlesForm.controls.addDomainBundles.reset();
      this.domainBundlesForm.controls.includeSubDomains.setValue(false);
      this.activeTab = 1;
    }
  }

  onPublishersAdd(): void {
    if (
      this.domainBundlesForm.controls.addPublishers.valid &&
      this.domainBundlesForm.controls.addPublishers.value &&
      this.domainBundlesForm.controls.addPublishers.value.length > 0
    ) {
      const publishersMediaToCreate: string[] = this.domainBundlesForm.controls.addPublishers.value;
      this.createMedia(publishersMediaToCreate);
      this.sendOutputsToParent();
      this.validateDomainBundleFilter();
      this.domainBundlesForm.controls.addPublishers.reset();
      this.domainBundlesForm.controls.includePublishers.setValue(false);
      this.activeTab = 1;
    }
  }

  createMedia(mediaToCreate: string[]) {
    let domainBundle: DomainBundle;
    mediaToCreate.forEach(d => {
      if (this.parentType === 'INVENTORY-PACKAGE') {
        domainBundle = {
          id: null,
          domainBundle: d,
          includeSubDomains: this.domainBundlesForm.controls.includeSubDomains.value,
          createdAt: null,
          packageId: (this.parent as InventoryPackage)?.packageId,
          updatedAt: null
        } as DomainBundle;
      } else {
        domainBundle = {
          id: null,
          domainBundle: d,
          includeSubDomains: this.domainBundlesForm.controls.includeSubDomains.value,
          createdAt: null,
          placementId: null,
          pubTsId: null,
          updatedAt: null
        } as DomainBundle;
      }
      this.domainBundleChanges = [...this.domainBundleChanges, {
        change: 'To be added',
        value: domainBundle.domainBundle,
        type: 'domain bundle'
      }];
      this.domainBundlePayload.addedDomainBundles = [...this.domainBundlePayload.addedDomainBundles, domainBundle];
    });
  }

  onButtonClick(buttonId: string): void {
    switch (buttonId) {
      case 'domainBundlesDownloadCsvButton':
        this.downloadToCsv();
        break;
      case 'domainBundlesDeleteAllButton':
        this.onDeleteAllClicked();
        break;
      default:
        break;
    }
  }

  onDeleteAllClicked(): void {
    this.domainBundleChanges = this.domainBundleChanges.filter(change => change.change === 'To be added');
    this.domainBundlePayload.updatedDomainBundles = [];
    this.domainBundlePayload.removedDomainBundles = [];

    this.domainBundleChanges = [...this.domainBundleChanges, {
      change: 'To be deleted',
      value: 'entire domains list',
      type: 'domains'
    }];
    this.domainBundlePayload.removeAllDomains = true;
    this.activeTab = 1;
    this.domainBundlesTable.tableClass = 'disable-table';
    this.validateDomainBundleFilter();
    this.sendOutputsToParent();
  }

  onDomainBundleUploadFinished(event: { file: NzUploadFile }): void {
    switch (event.file.status) {
      case FileStatus.DONE:
        this.uploadedFile = [event.file];
        if (this.parentType === 'RX-DEAL' || this.parentType === 'PAD_AGREEMENT') {
          this.domainBundleChangesBeforeFileUpload = this.domainBundleChanges;
          this.onDomainBundlesAdd(this.parsedUploadedFile.filter(domain => domain?.[0]).map(domain => domain[0].toString()));
        } else {
          this.domainBundleFileUpdated.emit(this.uploadedFile);
          const change = _.find(this.domainBundleChanges, ['type', 'file']);
          if (change) {
            change.value = event.file.name;
            this.domainBundleChanges = [...this.domainBundleChanges];
          } else {
            this.domainBundleChanges = [...this.domainBundleChanges, {
              change: 'To be added',
              value: event.file.name,
              type: 'file'
            }];
          }
          this.validateDomainBundleFilter();
          this.activeTab = 1;
        }
        this.sharedService.showNotification('success', 'File uploaded successfully');
        break;
      case FileStatus.ERROR:
        this.sharedService.showNotification('error', 'Domains/bundles upload file upload failed');
        break;
    }
  }

  onChangeSelectIconState(isOpen, id): void {
    this.selectIcons[id] = isOpen;
    this.validateDomainBundleFilter();
  }

  setFilterButtonValues(buttonAction: string, newValue: any): void {
    const buttonSet = 'domainBundlesTableButtons';
    const clone = cloneDeep(this[buttonSet]);
    const idx = findIndex(clone, ['action', buttonAction]);
    assign(clone[idx], newValue);
    this[buttonSet] = clone;
    this.cdr.detectChanges();
  }

  onTablePageChange(table: AppTable): void {
    this.domainBundlesTable.pageIndex = table.pageIndex;
    this.getDomainBundles();
  }

  onTableSortChange(table: AppTable): void {
    this.domainBundlesTable.pageIndex = table.pageIndex;
    this.domainBundlesTable.sortBy = table.sortBy;
    this.domainBundlesTable.sortDirection = table.sortDirection.toUpperCase();
    this.getDomainBundles();
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getButtonId(): string {
    return this.parentType !== 'TRAFFIC-SOURCE' ? 'domain_bundle_add_btn' : 'domain_bundle_add_btn_pts';
  }

  getButtonText(): string {
    return this.parentType === 'PAD_AGREEMENT' ? 'Add Domains' : 'Add Domains/Bundles';
  }

  private buildQuery(): JapiQuery {
    let query = this.sharedService.buildTableQuery(this.domainBundlesFiltersConfig, this.domainBundlesTable);
    if (!query.filter) {
      query = {...query, filter: {filters: []}};
    }
    if (this.parentType !== 'INVENTORY-PACKAGE') {
      query.filter.filters.push({
        fieldName: 'placementId',
        operation: 'EQUALS',
        value: this.placementId
      });
      query.filter.filters.push({
        fieldName: 'pubTsId',
        operation: 'EQUALS',
        value: this.pubTsId
      });
    } else {
      query.filter.filters.push({
        fieldName: 'packageId',
        operation: 'EQUALS',
        value: (this.parent as InventoryPackage)?.packageId
      });
    }
    return query;
  }

  private revertUploadedFileChanges(): void {
    this.uploadedFile = [];
    this.domainBundleFileRemoved.emit();
    this.activeTab = 0;
  }

  private revertDomainBundleChanges(): void {
    if (this.domainBundleChangesBeforeFileUpload.length) {
      this.domainBundleChanges = this.domainBundleChangesBeforeFileUpload;
      this.activeTab = 1;
    } else {
      this.domainBundleChanges = [];
      this.activeTab = 0;
    }
  }

  private createDomainFilter(): DomainFilter {
    const domainFilterValue = this.domainBundlesForm.controls.filterDomainBundle.value || 'DISABLED';
    return {
      isDomainFilterValid: this.domainBundlesForm.valid,
      filterDomainBundle: domainFilterValue,
      domainsCount: this.domainBundleTotalElements,
      isDomainsLoading: this.isDomainsLoading
    } as DomainFilter;
  }

  private makeDeletedDomainChanges(domainToDelete: DomainBundle, event: any): void {
    domainToDelete ??= {} as DomainBundle;
    const deletedDomain = event.row;
    delete deletedDomain.changes;
    this.domainBundlePayload.removedDomainBundles = [...this.domainBundlePayload.removedDomainBundles, deletedDomain];
    domainToDelete.changes = 'To be deleted';
    this.domainBundleChanges = [...this.domainBundleChanges, {
      change: 'To be deleted',
      value: event.row.domainBundle,
      type: 'domain bundle'
    }];
    this.sendOutputsToParent();
  }

  private getParentInfo(): { id: number; name: string; tsId?: number } {
    switch (this.parentType) {
      case 'INVENTORY-PACKAGE':
        return {
          id: (this.parent as InventoryPackage).packageId,
          name: (this.parent as InventoryPackage).description
        };
      case 'PLACEMENT':
        return {
          id: (this.parent as Placement).placementId,
          name: (this.parent as Placement).placementName
        };
      case 'TRAFFIC-SOURCE':
        return {
          id: (this.parent as PublisherTrafficSource).pubTsId,
          name: (this.parent as PublisherTrafficSource).trafficSourceName,
          tsId: (this.parent as PublisherTrafficSource).trafficSourceId
        };
      case 'PAD_AGREEMENT':
        return {
          id: (this.parent as PadAgreement).paId,
          name: (this.parent as PadAgreement).agreementName
        };
      default:
        return { id: null, name: null };
    }
  }

  private downloadRxDealDomains(): void {
    this.api.downloadDomainBundles({
      parentType: 'RX-DEAL',
      id: null,
      segmentId: this.segmentId
    }).pipe(
      takeUntil(this.unsubscribe$),
      catchError(async () => this.sharedService.onHttpCompletionResolve(
        false,
        'Failure',
        'Domain bundles download failed',
        '',
        ''
      ))
    ).subscribe();
  }

  private downloadParentDomains(parentInfo: { id: number; name: string; tsId?: number }): void {
    this.api.downloadDomainBundles({
      parentType: this.parentType,
      id: parentInfo.id,
      name: parentInfo.name,
      tsId: parentInfo.tsId
    }).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      error: () => {
        this.sharedService.onHttpCompletionResolve(
          false,
          'Failure',
          'Domain bundles download failed',
          '',
          ''
        );
      }
    });
  }
}
