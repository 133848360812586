import { AppFilter, AppTable, AppTableColumn } from '../../../shared/_models/models';

export const placementsPermissionsConfig = {
  create: 'supply.placement.create',
  update: 'supply.placement.update',
  delete: 'supply.placement.delete',
  read: 'supply.placement.read',
};

export const placementsFiltersConfig: AppFilter[] = [
  new AppFilter('placementsSearchFilter', 'SEARCH', '', 'Placement ID/Name', 'Search by placement id/name',
    'default', true, true, {nameProp: 'placementName', idProp: 'placementId'}),
];

export const placementsTableButtons = [
  {id: 'bulk_create_placement_btn', type: 'SECONDARY-BUTTON-WITH-PERMISSIONS', title: 'Bulk Create', hasLoadingSpinner: false, auth: 'canCreate'},
  {id: 'create_placement_btn', type: 'PRIMARY-BUTTON', title: 'Create Placement', hasLoadingSpinner: false, auth: 'canCreate'}
];
export const placementsTableConfig: AppTable = new AppTable('placementsTable', 1, 10, 'createdAt', 'DESC', true, true);
export const placementsTableColumns: AppTableColumn[] = [
  {id: 'placementsStatus', prop: 'status', displayName: 'Status', type: 'toggle', actionId: 'placementStatusToggle', isSortEnabled: false},
  {id: 'placementsId', prop: 'placementId', displayName: 'ID', type: 'text', isSortEnabled: true},
  {id: 'placementsName', prop: 'placementName', displayName: 'Name', type: 'text', isSortEnabled: true},
  {id: 'placementsCreatedAt', prop: 'createdAt', displayName: 'Created at (EST)', type: 'date', isSortEnabled: true},
  {
    id: 'placementsActions', prop: 'actions', displayName: 'Actions', type: 'actions', isSortEnabled: false,
    actions: [
      {id: 'placementEdit', name: 'edit', auth: 'canUpdate', displayName: 'Edit'},
      {id: 'placementDuplicate', name: 'duplicate', auth: 'canCreate', displayName: 'Duplicate'},
      {id: 'placementView', name: 'view', auth: 'canRead', displayName: 'View'},
    ]
  }
];

export const PLACEMENTS_MOCK: any = [
  {
    badv: null,
    battr: null,
    bcat: null,
    btype: null,
    cat: null,
    checksum: null,
    commissions: null,
    coppa: null,
    createdAt: '2021-02-15T04:57:00.000-0500',
    defaultAppName: '',
    defaultAppStoreUrl: '',
    defaultDomainBundle: '',
    deletedAt: null,
    demandProfile: 'open',
    domainChecksum: null,
    dspAccountExcludeList: null,
    externalSourceId: '',
    filterDomainBundle: 'DISABLED',
    floors: [{
      bidFloor: 0.002,
      createdAt: '2021-02-13T16:22:25.000-0500',
      floorAdjustment: 0,
      floorAdjustmentType: 'PERCENT',
      id: 35752,
      impType: 'NATIVE',
      curatedDealFloor: 0,
      internalDspFloor: 0,
      placementId: 201852,
      publisherTrafficSourceId: 219,
      updatedAt: '2021-02-13T16:22:25.000-0500'
    },
      // geo floor
      {
        bidFloor: 0.002,
        curatedDealFloor: 0,
        internalDspFloor: 12,
        countryAlpha2: 'AD',
        createdAt: '2021-02-13T16:22:28.000-0500',
        deviceType: 'ANY',
        id: 3252,
        supplyPlacementFloorId: 35752,
        updatedAt: '2021-02-13T16:22:28.000-0500'
      }],
    lda: false,
    mediaType: 'APP',
    notes: '',
    placementId: 201904,
    placementName: 'meir-test - copy',
    pubTs: null,
    publishedAt: null,
    status: 'ACTIVE',
    tldBadv: null,
    updatedAt: '2021-02-15T04:57:00.000-0500',
    vpaidSupport: false
  }
];
