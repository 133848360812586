<nz-form-item [formGroup]="formPart">
    <div nz-row nzJustify="space-between" class="align-items-baseline">
      <nz-form-label>{{ label }}</nz-form-label>
      <nz-radio-group
        formControlName="clusivity"
        [nzDisabled]="areNoItemsSelected"
        [id]="radioGroupElementId">
        <label nz-radio nzValue="include">Include</label>
        <label nz-radio nzValue="exclude">Exclude</label>
      </nz-radio-group>
    </div>

    <nz-form-control
      [nzValidateStatus]="errorMessage ? 'error' : null"
      [nzErrorTip]="errorMessage">

      <ng-content select="app-clusivity-list-select-options, app-clusivity-list-select-option-groups" />

    </nz-form-control>
</nz-form-item>
