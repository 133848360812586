<nz-select
    [id]="selectElementId"
    [formControl]="itemsFormControl"
    [nzPlaceHolder]="selectListPlaceholderText"
    nzShowSearch
    nzMode="multiple"
    [nzMaxTagCount]="maxSelectPreviewCount"
    nzShowArrow="false"
    [nzAllowClear]="allowClear"
    class="full-width">

    <nz-option-group *ngFor="let optionGroup of optionGroups" [nzLabel]="optionGroup.groupDisplayName">
        <nz-option *ngFor="let option of optionGroup.options"
                    [nzLabel]="option.displayName" [nzValue]="option.item">
        </nz-option>
    </nz-option-group>
</nz-select>