<nz-select
    [id]="selectElementId"
    [formControl]="itemsFormControl"
    [nzPlaceHolder]="selectListPlaceholderText"
    nzShowSearch
    nzMode="multiple"
    [nzMaxTagCount]="maxSelectPreviewCount"
    nzShowArrow="false"
    [nzAllowClear]="allowClear"
    class="full-width">

    <nz-option *ngFor="let option of options"
        [nzLabel]="option.displayName" [nzValue]="option.item">
    </nz-option> 

</nz-select>