<nz-upload
    [class]="uploadClass"
    [nzType]="type"
    [nzMultiple]="multipleFiles"
    [nzAction]="action"
    [nzLimit]="numberOfFiles"
    [nzDisabled]="disabledUploadButton"
    [nzShowUploadList]="showUploadList === true ? showList : false"
    [nz-tooltip]="tooltip"
    [nzListType]="listType"
    [(nzFileList)]="fileList"
    (nzChange)="onChange($event)"
    [nzBeforeUpload]="beforeUpload"
    (nzRemove)="onRemove($event)"
    [nzCustomRequest]="customRequest"
    >
    <div class="content-wrapper" (click)="preDialogActionsTrigger()">
        <ng-content></ng-content>
    </div>
</nz-upload>