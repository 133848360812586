import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import * as _ from 'lodash';

import { AppFilter, SortOption } from '../../_models/models';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-input',
  templateUrl: './app-input.component.html',
  styleUrls: ['./app-input.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, multi: true,
      useExisting: forwardRef(() => AppInputComponent),
    }
  ],
})
export class AppInputComponent implements OnInit, ControlValueAccessor, OnDestroy {

  sortByAsc: boolean;
  @Input() inputControl: UntypedFormControl | AbstractControl = new UntypedFormControl([]);

  // Using compareWith function on select value from list in NZ-SELECT element had very bad performance on ng-zorro v8
  // To workaround it, using here getter-setter, in setter the options are map to flat array of values
  groups: any[];
  options: any[];
  _inputConfig: AppFilter;
  @Input()
  set inputConfig(inputConfig) {

    this.groups = inputConfig.groups || undefined;
    if (!this.groups) {
      this.options = inputConfig.possibleValues ? inputConfig.possibleValues.map(item => item.displayName) : [];
    }
    this._inputConfig = cloneDeep(inputConfig);
    // a weird workaround zorro v12 not rendering single value from array for default mode
    if (this._inputConfig && this._inputConfig?.selectMode === 'default' &&
      (this._inputConfig?.type === 'SELECT' || this._inputConfig?.type === 'SELECT_WITH_SORT') &&
      this._inputConfig.selectedValues?.length > 0) {
      this.inputControl.setValue(this._inputConfig.selectedValues[0].displayName, {onlySelf: true, emitEvent: false});
    }
    if ((this.inputConfig.type === 'SELECT' || this.inputConfig.type === 'SELECT_WITH_SORT')
      && inputConfig.selectedValues && inputConfig.selectedValues.length > 0) {
      this.disableOptions(inputConfig.selectedValues.map(item => item.value));
    }
    if (this.inputConfig.type === 'SELECT_WITH_SWITCH') {
      // not int use?
    }
  }

  get inputConfig(): AppFilter {
    return this._inputConfig;
  }

  @Output() inputValueChanged = new EventEmitter();
  @Output() inputSelectSearched = new EventEmitter();
  @Output() inputSortByChanged = new EventEmitter<SortOption>();
  @Output() datePickerOpenChanged = new EventEmitter();

  isSelectOpen = false;
  debounceTime: number;

  private unsubscribe$ = new Subject<void>();

  searchFilterDebounce = _.debounce(searchValue => {
    this.inputSelectSearched.emit(searchValue);
  }, 500, {});

  ngOnInit(): void {
    // in reports we don't need debounce on filters, because request fires only on click "GENERATE"
    // where debounce may cause request to fire with previous value on click
    // in tables with reactive filters, we need a longer debounce to allow user more actions before request is fired
    // TODO - define a default and per AppFilter specific debounce time to allow
    //  different behavior when, for example, validations are required
    this.debounceTime = this.inputConfig.id.includes('Report') ? 0 : 700;

    if (this.inputConfig.defaultValues) {
      if (this.inputConfig.type === 'SELECT' && this.inputConfig.selectMode === 'multiple') {
        this.inputControl.setValue(this.inputConfig.defaultValues.length
          ? this.inputConfig.defaultValues.map(item => item.displayName)
          : []);
      } else if (this.inputConfig.type === 'SELECT_WITH_SORT' || this.inputConfig.type === 'SELECT') {
        this.inputControl.setValue(this.inputConfig.defaultValues[0]?.displayName);
      } else {
        this.inputControl.setValue(this.inputConfig.defaultValues);
      }
    }
    if (this.inputConfig.isDisabled) {
      this.inputControl.disable();
    }
    this.inputControl.valueChanges.pipe(debounceTime(this.debounceTime), takeUntil(this.unsubscribe$))
      .subscribe(controlValue => {
        if (this.inputConfig.type === 'SELECT' || this.inputConfig.type === 'SELECT_WITH_SORT') {
          //  extract from possible values list and send the object {id: 1, displayName: 'abc', value: 123}
          let selectedItems = this.getSelectedItems(controlValue);
          // special treatment for 'tags' filter, as it does not have possible values, in order to stay generic,
          // we populate user typed values into same structure as regular select
          if (this.inputConfig.selectMode === 'tags') {
            selectedItems = controlValue.reduce((res, val, index) => {
              res.push({id: index, displayName: val, value: val});
              return res;
            }, []);
          }
          this.inputValueChanged.emit(selectedItems);
        } else if (this.inputConfig.type === 'SELECT_WITH_SWITCH') {
          const selectedItems = controlValue == null ? [] : this.inputConfig.possibleValues.reduce((res, val) => {
            if (controlValue.includes(val.displayName)) {
              res.push(val);
            }
            return res;
          }, []);
          this.inputValueChanged.emit(selectedItems);
        } else {
          // otherwise: input is number or date, send the flat value
          this.inputValueChanged.emit(controlValue);
        }
      });
  }

  getSelectedItems(controlValue: any) {
    controlValue = Array.isArray(controlValue) ? controlValue : [controlValue];
    return controlValue == null ? [] : this.inputConfig.possibleValues.reduce((res, val) => {
      if (controlValue.includes(val.displayName)) {
        res.push(val);
      }
      return res;
    }, []);
  }

  // deal with use case when user clicks on generate button without closing the dates picker,
  // ng-zorro dates picker updates formControl value only on click outside event, and does not explicitly exposes on-the fly changes
  // thus occasionally the click generate grabs previous values from store filters
  onDatesPickerOpenChange(isDatePickerOpen: boolean): void {
    this.datePickerOpenChanged.emit({isDatePickerOpen: isDatePickerOpen});
  }

  onSelectChangeValue(event) {
    // manage exceptions here for immediate effect (not possible in value change debounce subscription)
    this.disableOptions(event);
  }

  onSelectWithSwitchChangeValue() {
    this.inputControl.updateValueAndValidity();
  }

  disableOptions(selectedValues) {
    if (this.inputConfig.id === 'publishersStatusFilter') {
      // reset all == if none selected -> enable all
      this.inputConfig.possibleValues[0].isDisabled = false;
      this.inputConfig.possibleValues[1].isDisabled = false;
      this.inputConfig.possibleValues[2].isDisabled = false;

      // if active or inactive selected -> disable deleted
      if (selectedValues.includes('Active') || selectedValues.includes('Inactive')
        || selectedValues.includes('ACTIVE') || selectedValues.includes('INACTIVE')) {
        this.inputConfig.possibleValues[2].isDisabled = true;
      }

      // if deleted selected -> disable active and inactive
      if (selectedValues.includes('Deleted') || selectedValues.includes('DELETED')) {
        this.inputConfig.possibleValues[0].isDisabled = true;
        this.inputConfig.possibleValues[1].isDisabled = true;
      }
    }
  }

  compareByValue = (o1: any, o2: any) => {
    return o1 && o2 ? o1.value === o2.value : o1 === o2;
  }

  onSearchClear() {
    this.inputControl.setValue(this.inputConfig.defaultValues ? this.inputConfig.defaultValues : '');
  }

  onSearch(searchValue: string) {
    this.searchFilterDebounce(searchValue);
  }

  onChangeSelectIconState() {
    this.isSelectOpen = !this.isSelectOpen;
  }

  disabledDate = (current: Date): boolean => {
    if (this.inputConfig.limitFutureDateRange || this.inputConfig.limitPastDateRange) {
      if (current > this.inputConfig.limitFutureDateRange || current < this.inputConfig.limitPastDateRange) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // control value accessor interface functions
  writeValue(obj: any): void {
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  // ==========================================

  sortByToggle(): void {
    this.sortByAsc = !this.sortByAsc;
    const sortDirection = this.sortByAsc ? 'desc' : 'asc';
    this.inputSortByChanged.emit(sortDirection);
  }
}
