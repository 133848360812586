import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppFilter, AppTable, AuthPermissions, UploadFileType} from '../../_models/models';
import {
  blockedAdvertisersFiltersConfig,
  blockedAdvertisersTableButtons,
  blockedAdvertisersTableColumns,
  blockedAdvertisersTableConfig
} from './blocked-advertisers.config';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {assign, cloneDeep, findIndex} from 'lodash';
import {SharedService} from '../../_services/shared.service';
import {ApiService} from '../../_services/api.service';
import {environment} from '../../../../environments/environment';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { CSV_FILES } from '../../_constants/file-upload';

@Component({
  selector: 'app-blocked-advertisers',
  templateUrl: './blocked-advertisers.component.html',
  styleUrls: ['./blocked-advertisers.component.less']
})
export class BlockedAdvertisersComponent implements OnInit {
  _badvData: { badvName: string }[] = [];
  filterBadvData: { badvName: string }[] = [];
  fileTypes: UploadFileType[] = CSV_FILES;

  @Input() badvDataSize;
  @Input() editMode;
  @Input() placementId;

  @Input()
  set badvData(badv) {
    this._badvData = badv ? badv : [];
    this.filterBadvData = cloneDeep(this.badvData).sort((a, b) => a.badvName > b.badvName ? 1 : -1);
    this.setFilterButtonValues('deleteAll', {isDisabled: !this.filterBadvData || this.filterBadvData.length === 0});
    this.setFilterButtonValues('download', {isDisabled: this.isDownloadDisabled()});
  }
  get badvData() {
    return this._badvData;
  }
  @Output() updatedBadv: EventEmitter<{badvName: string }[] > = new EventEmitter();
  selectIcons: any = {};
  blockedAdvertisersTable: AppTable = {...blockedAdvertisersTableConfig};
  domainBundlesTableButtons = blockedAdvertisersTableButtons;
  blockedAdvertisersFiltersConfig = blockedAdvertisersFiltersConfig;
  blockedAdvertisersTableColumns = blockedAdvertisersTableColumns;
  authPermissions: AuthPermissions;
  badvForm: UntypedFormGroup;
  uploadBlockedAdvertisersFromFileUrl: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private api: ApiService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  isDownloadDisabled(): boolean {
    if (this.editMode === 'EDIT') {
      return this.badvDataSize === 0;
    } return true;
  }

  ngOnInit(): void {
    this.authPermissions = {
      canRead: true,
      canCreate: true,
      canUpdate: true,
      canDelete: true,
    };
    this.badvForm = this.formBuilder.group({
      badv: [null]
    });
    this.uploadBlockedAdvertisersFromFileUrl = environment.javaApiUrl +
    `/placement/blocked-advertisers/file`;
  }

  onBadvAdd(): void {
    const addedBadvData:  { badvName: string }[] = this.badvForm.controls.badv.value.map(badvName => ({badvName: badvName.trim()}));
    const uniqueAddedBadv:  { badvName: string }[] = addedBadvData
      .filter(b => !this.badvData.some(x => b.badvName.toLowerCase() === x.badvName.toLowerCase()));
    const numberOfDuplicatedItems: number = addedBadvData.length - uniqueAddedBadv.length;
    if (numberOfDuplicatedItems > 0) {
      if (numberOfDuplicatedItems === addedBadvData.length) {
        this.sharedService.showNotification('success', 'Success', `Finished, though all blocked advertisers to add detected as duplicate`);
      } else {
        this.sharedService.showNotification('success', 'Success', `Created ${uniqueAddedBadv.length} blocked advertisers, ${numberOfDuplicatedItems} detected as duplicated`);
      }
    } else {
      this.sharedService.showNotification('success', 'Success', `Blocked advertisers created`);
    }
    this.badvData = [...this.badvData, ...uniqueAddedBadv];
    this.filterBadvData = cloneDeep(this._badvData);
    this.sendOutputsForUpdatedBadvToParent();
    this.badvForm.reset();
  }

  onRowAction(event: any) {
    this.badvData = this.badvData.filter(b => b.badvName !== event.row.badvName);
    this.sendOutputsForUpdatedBadvToParent();
  }

  sendOutputsForUpdatedBadvToParent(): void {
    const badvToEmit =  this._badvData;
    this.updatedBadv.emit(badvToEmit);
  }

  onFiltersChange($event: {filters: AppFilter[]; filterId: string}): void {
    const searchValue = $event.filters[0].selectedValues;
    this.filterBadvData = this.badvData.filter(b => b.badvName.includes(searchValue));
  }

  onButtonClick(buttonId: string): void {
    switch (buttonId) {
      case 'BlockedAdvertisersDeleteAllButton':
        this.deleteAllBadv();
        break;
      case 'BlockedAdvertisersDownloadCsvButton':
        this.downloadCsv();
        break;
      default:
        break;
    }
  }

  deleteAllBadv(): void {
    this.badvData = [];
    this.sendOutputsForUpdatedBadvToParent();
  }

  downloadCsv() {
    this.api.downloadBlockedAdvertisers(this.placementId)
      .subscribe(() => {
        },
        () => {
          this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Blocked advertisers download failed', ``, '');
        });
  }

  public onBlockedAdvertisersUploadFinished(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        // Do Nothing for now
        break;
      case 'done':
        const originalBadvDataLenght: number = this.badvData.length;
        const originalAddedBadvFromFile: number = info.file.response.data.length;
        const addedBadvDataFromFile: { badvName: string }[] = info.file.response.data
          .filter(b => !this.badvData.some(x => b.toUpperCase() === x.badvName.toUpperCase()))
          .map(badv => ({badvName: badv}));
        this.badvData = [...this.badvData, ...addedBadvDataFromFile];
        const actualAddedBadvs: number = this.badvData.length - originalBadvDataLenght;
        const duplicatedBadvs: number = originalAddedBadvFromFile - actualAddedBadvs;
        this.sendOutputsForUpdatedBadvToParent();
        if (addedBadvDataFromFile.length === 0) {
          this.sharedService.showNotification('success', 'File uploaded successfully', 'though all blocked advertisers to add detected as duplicated');
        } else if (duplicatedBadvs > 0) {
          this.sharedService.showNotification('success', 'File uploaded successfully', `Created ${actualAddedBadvs} blocked advertisers, ${duplicatedBadvs} detected as duplicated`);
        } else {
          this.sharedService.showNotification('success', 'File uploaded successfully');
        }
        break;
      case 'error':
        this.sharedService.showNotification('error' , 'File upload failed', 'Could not get blocked Advertisers from file');
        break;
    }
  }

  setFilterButtonValues(action, newValue) {
    const buttonSet = 'domainBundlesTableButtons';
    const clone = cloneDeep(this[buttonSet]);
    const idx = findIndex(clone, ['action', action]);
    assign(clone[idx], newValue);
    this[buttonSet] = clone;
    this.cdr.detectChanges();
  }
}
