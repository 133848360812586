import { Component, Input } from '@angular/core';
import { ClusivityListSelectOptionGroup } from './clusivity-list-select-option-group.model';
import { ClusivityListItemsComponent } from '../clusivity-list-items.component';

@Component({
  selector: 'app-clusivity-list-select-option-groups',
  templateUrl: './clusivity-list-select-option-groups.component.html',
  styleUrls: ['./clusivity-list-select-option-groups.component.less'],
  providers: [{
    provide: ClusivityListItemsComponent,
    useExisting: ClusivityListSelectOptionGroupsComponent
  }]
})
export class ClusivityListSelectOptionGroupsComponent<TItem> extends ClusivityListItemsComponent<TItem> {
  @Input({ required: true }) optionGroups: ClusivityListSelectOptionGroup<TItem>[];

  @Input() selectElementId?: string;
  @Input() selectListPlaceholderText?: string;
  @Input() maxSelectPreviewCount?: number;
  @Input() allowClear = false;
}
