import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { extractOnTouchedStream } from '../../_common/touched-stream.helper';

export abstract class ClusivityListItemsComponent<TItem> {
  protected itemsFormControl = new FormControl(<TItem[]>[]);

  private _onTouchedStream: Observable<void>;
  get onTouchedStream(): Observable<void> {
    if (!this._onTouchedStream) {
      this._onTouchedStream = extractOnTouchedStream(this.itemsFormControl);
    }
    return this._onTouchedStream;
  }

  get items(): Observable<TItem[]> {
    return this.itemsFormControl.valueChanges;
  }

  set items(items: TItem[]) {
    this.itemsFormControl.patchValue(items);
  }

  enable(): void {
    this.itemsFormControl.enable();
  }

  disable(): void {
    this.itemsFormControl.disable();
  }
}
